<nb-card style="max-width:428px;">
  <form #userform="ngForm"  [formGroup]="aFormGroup" (ngSubmit)="onSubmit()">
    <nb-card-header style="padding-top: 12px; padding-bottom: 12px;">
      <div class="row">
        <div class="col-md-12" style="color: var(--black-gray, #363740);
        text-align: center;
        /* Semi Bold - 18 */
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;">Tolak Pesanan</div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div style="color: var(--black-gray, #363740);
      text-align: center;
      /* Medium - 16 */
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;">Konfirmasi tolak pesanan, Silahkan masukkan  alasan pembatalan pesanan.</div>
      <br/><br/>
      <!-- <div class="row">
        <div class="col-md-12">
          <nb-select placeholder="Pilih Alasan Pembatalan" [(selected)]="selectedReason" style="min-width: 320px;">
            <nb-option  *ngFor="let item of reason" [value]="item.id">{{item.name}}</nb-option>
          </nb-select>
        </div>
      </div> -->
      <div class="row" style="padding-left:40px;padding-right:40px">
        <div class="mb-3">
            <select placeholder="Pilih Alasan Pembatalan" formControlName="reason" class="form-control" (change)="changeWebsite($event)">
                <option *ngFor="let item of reasons" [value]="item.id">{{item.name}}</option>
            </select>
            <div *ngIf="reason?.touched && reason?.invalid">
                <small class="text-danger" *ngIf="reason?.errors?.['required']">Jenis pesan harus diisi</small>
            </div>
          </div>
          </div>
    </nb-card-body>

    <nb-card-footer>
      <div class="row" style="padding:25px;">
      <div class="col-md-8" style="text-align: left;">
        <button type="button" (click)="confirm()" [disabled]="userform.form.invalid" class="btn btn-bg-blue pull-right" style="background-color:#EB5757; max-width:324px; color:#ffffff">
          <img src="../../../assets/icon/icon-export-csv.svg">
          Tolak Pesanan
        </button>
      </div>

      <div class="col-md-4" style="text-align: right;">
        <button type="button" class="btn btn-bg-blue pull-right" (click)=dismiss() style="background-color:transparent; border-color: #007bff; max-width:114px; color:#007bff">
          <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Kembali
        </button>
      </div>
    </div>
    </nb-card-footer>
    </form>
  </nb-card>