import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiClient } from '../../services/api.client';
import { loginResponse } from '../../models/login_otp_dto';
import { RC_SUCCESS } from '../../rc_const';

@Component({
  selector: 'ngx-failed-resp',
  templateUrl: './failed-resp.component.html',
  styleUrls: ['./failed-resp.component.scss']
})
export class FailedRespComponent  {
  @Input() rc;
  @Input() msg;
  
  constructor(public activeModal: NgbActiveModal, private router: Router, private apiClient: ApiClient) { }

  tutup(){
    this.activeModal.close();
  }

  async verifThisOtp(){
      // this.loading = true;
      var refresh_token = localStorage.getItem("rf");
      var token = localStorage.getItem("atk");
      let req = await this.apiClient.post<loginResponse>({
        endpoint: "/auth/refresh_token",
        params:{
          "token": token,
          "refresh_token": refresh_token
        }
      });



      if(req.respcode=== RC_SUCCESS){
        localStorage.setItem("rf", req.data.refresh_token);
        localStorage.setItem("atk", req.data.token);
        localStorage.setItem("addm", "0");
        // this.router.navigate(['/pages/orders/market-place']);
        this.router.navigate(['/pages/orders/market-place'])
        .then(() => {
          window.location.reload();
        });
      } else {
        // this.loading = false
        // this.errorMessageOtp = "invalid OTP. Please enter correct OTP number!";
      }
    
  }


}
