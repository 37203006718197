<div
  class="modal-header"
  style="text-align: center; border: solid red 0px; display: none"
></div>
<div
  class="modal-body"
  style="border: solid red 0px; min-height: 260px; margin-top: 10px"
>
  <div class="row" style="width: 100%; position: relative">
    <div class="col-sm"></div>
    <div class="col-sm" style="text-align: center; align-items: center">
      <img src="../../../../assets/icon/failed.svg" />
    </div>
    <div class="col-sm"></div>
  </div>
  <div class="row" style="width: 100%; position: relative; margin-top: 16px">
    <div class="col-sm"></div>
    <div class="col-sm" style="text-align: center; align-items: center">
      <span style="font-weight: bold; font-size: 18px; font-family: 'Poppins'"
        >Gagal</span
      >
    </div>
    <div class="col-sm"></div>
  </div>

  <div class="row" style="width: 100%; margin-top: 16px">
    <div class="col-md-1"></div>
    <div class="col-md-10 justify-content-center text-center labelpin">
      Something went wrong
    </div>
    <div class="col-md-1"></div>
  </div>
</div>

<div class="modal-footer" style="border: solid red 0px">
  <div class="col-sm-12" style="text-align: center">
    <button
      type="button"
      (click)="tutup()"
      class="btn btn-bg-blue"
      style="background-color: #f2994a; max-width: 324px; color: #ffffff"
    >
      Coba Lagi
    </button>
  </div>
</div>
