export const RC_SUCCESS = 1
export const RC_FAILED = 0
export const RC_PENDING = 2
export const RC_INVALID_AMOUNT = 3
export const RC_PRODUCT_NOT_AVAILABLE = 4
export const RC_NOT_ENOUGH_DEPOSIT = 5
export const RC_TRID_NOT_FOUND = 6
export const RC_UNAUTHORIZED = 7
export const RC_INVALID_PARAM = 8
export const RC_SETPRICE_ERROR = 9
export const RC_SUBID_NOT_VALID = 11
export const RC_SUBID_TEMPORARY_SUSPEND = 12
export const RC_BILLER_ERROR = 13
export const RC_MSISDN_BLOCKED = 14
export const RC_SUBID_PERMANENT_SUSPEND = 15
export const RC_BILL_PAID = 16
export const RC_NO_BILL = 17
export const RC_PAY_REJECTED = 18
export const RC_OUTSTANDING_BILL = 19
export const RC_UNDEFINED_ERROR = 20
export const RC_AMOUNT_INCORRECT = 21
export const RC_MERCHANT_CODE_UNAVAILABLE = 22
export const RC_BILLER_CODE_UNAVAILABLE = 23
export const RC_TRX_CANNOT_BE_PROCESSED = 24
export const RC_TRX_DOUBLE = 25
export const RC_INVALID_MSISDN = 43
export const RC_MSISDN_NOT_FOUND = 46
export const RC_MSISDN_IN_GRACE_PERIOD = 47
export const RC_MSISDN_EXPIRED = 48
export const RC_INQUIRY_FAIL = 50
export const RC_INQ_PAY_TOO_LONG = 51
export const RC_INSUFFICIENT_BALANCE_TO_TRANSFER = 62
export const RC_TRANSFER_TO_SAME_AGENT = 62
export const RC_WAITING = 80
export const RC_MAINTENANCE = 81
export const RC_PENDING_SUCCESS = 82

export const RC_AGENT_ALREADY_EXISTS = 91
export const RC_INVALID_UPLINE_ID = 92
export const RC_TOKEN_EXPIRED = 92
export const RC_INVALID_TOKEN = 93
export const RC_NOT_AUTHORIZED = 94
export const RC_INVALID_SIGNATURE = 95
export const RC_TEST_NOT_FOUND = 98
export const RC_TOO_MANY_REQUEST = 99

export const RC_UNKNOWN_ERROR = 100
export const RC_ACTIVE_TICKET_FOUND = 101
export const RC_BANK_CLOSED = 102
export const RC_NO_ACTIVE_TICKET = 103
export const RC_TICKET_EXPIRED = 104
export const RC_TICKET_PAID_SUCCESSFULLY = 105
export const RC_TICKET_NOT_FOUND = 106
export const RC_BANK_NOT_AVAILABLE = 107
export const RC_DAILY_LIMIT_EXCEEDED = 108
export const RC_MONTHLY_LIMIT_EXCEEDED = 109
export const RC_OTP_INVALID = 120
export const RC_OTP_EXPIRED = 121
export const RC_TOKEN_AUTH_FAILED = 122

export const RC_MSISDN_EXIST = 125
export const RC_EMAIL_EXIST = 126

export const RC_OLD_PIN_INVALID = 128
export const RC_NIK_EXIST = 129
export const RC_FAV_SUB_ID_EXIST=130
export const RC_SYSTEM_ERROR = 200
export const RC_IMAGE_NULL = 201
export const RC_LIMIT_OTP = 202


export const RC_INVALID_PIN = 123



