
import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import { SeoService } from './@core/utils/seo.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiClient } from './services/api.client';

@Component({
  selector: 'ngx-app',
  templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'nabila-webtrx';
  isLogin = false;
  loading:boolean = false;
  @Input()agent_name:string
  @Input()saldo_deposit:string = '0'
  @Input()last_topup_date:string = ''
  @Input()test = ''
  public innerWidth: any;
  persenmargin;
  firstlog;
  loginV = environment.logonV;

  constructor(private analytics: AnalyticsService, private seoService: SeoService, private router:Router, private http: HttpClient, private apiClient: ApiClient) {
   
  }

  ngOnInit(): void {
    this.analytics.trackPageViews();
    this.seoService.trackCanonicalChanges();
    this.innerWidth = window.innerWidth;

    if(this.innerWidth > 1366){
      this.persenmargin = 1
    }else{
      this.persenmargin = 5
    }
    this.initValidation()
  }
  background = 'background-background100';



  accDistribute(a){
    if(a=='em_distribute_u'){
      return "distrib_username";
    }
    if(a=='em_distribute_p'){
      return "distrib_pass";
    }
  }



  initValidation(){
  
    var atk = localStorage.getItem("atk")
    if(atk === '' || atk === null){
      this.isLogin = false;
      this.router.navigate(['/login']);
    } else {
      let letgo = localStorage.getItem('firstlog');
      this.firstlog = parseInt(letgo) + 1;
      localStorage.setItem('firstlog',this.firstlog);

      this.isLogin = true;
      if(this.firstlog<=1){


        this.router.navigate(['/pages/orders/market-place']);

      }

    }

  }


  onSuccessLogin(value){
  
    this.test = value;
    this.isLogin = value;
    if(this.isLogin){
     
      window.location.reload()
     

    } else {
    }

  }


  

  onSidebarClick(value){
    if(value){
      this.background = 'background-background200'
    } else{
      this.background = 'background-background100'
    }
  }
}
