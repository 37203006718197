<nb-card>
    <nb-card-header style="padding-top: 12px; padding-bottom: 12px;">
      <div class="row">
        <div class="col-md-12" style="font-size: 36px; margin-top: 17px; font: Poppins; text-align: center;">Pilih Produk Kosong</div>
      </div>
    </nb-card-header>
    <nb-card-body>
        <div class="row">
            <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <th><input type="checkbox" [checked]="isAllCheckBoxChecked()" (change)="checkAllCheckBox($event)"></th>
                <th>No</th>
                <th>Nama Produk</th>
              </thead>
              <tbody>
                <tr *ngFor="let e of data_confirmation[0]['order_items']; let i = index; let odd = odd" (click)="addThisPersonToArray(e,  $event.target.checked)" [ngClass]="odd ? 'odd_col' : 'even_col'">
                  <!-- <td><input type="checkbox" value="{{e.id}}" [(ngModel)]="data[i].checked"></td> -->
                  <td style="text-align:center">
                    <input type="checkbox" value="{{e.id}}" [(ngModel)]="e.checked" (change)="checkboxChanged($event, e)">
                  </td>
                  <td>{{i+1}}</td>
                  <td>{{e.product_name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
    </nb-card-body>

    <nb-card-footer>
      <div class="row">
      <div class="col-md-6" style="text-align: left;">
        <button type="button" (click)="rejectOrder()" class="btn btn-bg-blue pull-right" style="background-color:#EB5757; max-width:304px; color:#ffffff">
          <img src="../../../assets/icon/icon-export-csv.svg">
          Tolak Pesanan
        </button>
      </div>

      <div class="col-md-6" style="text-align: right;">
        <button type="button" (click)="dismiss()" class="btn btn-bg-blue pull-right" style="background-color:transparent; border-color: #007bff; max-width:114px; color:#007bff">
          <img src="../../../assets/icon/icon-export-csv.svg">
          Kembali
        </button>
      </div>
    </div>
    </nb-card-footer>
  </nb-card>