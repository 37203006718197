import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import {Router} from '@angular/router';

import { RC_SUCCESS} from '../../rc_const';
import * as forge from 'node-forge';
import { loginInfoResponse, loginOtpResponse, loginResponse } from '../../models/login_otp_dto';
import { ApiClient } from '../../services/api.client';
import * as $ from "jquery";
import { NgOtpInputModule } from 'ng-otp-input';

@Component({
  selector: 'ngx-login-otp',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.scss']
})
export class LoginOtpComponent  implements  OnInit {
  @Output() SuccessLogin = new EventEmitter();
  loading:boolean = false;
  token: string|undefined;
  registrationForm: FormGroup;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  passwordinValid: boolean = false;
  userinValid: boolean = false;
  msisdninValid: boolean = false;
  public aFormGroup: FormGroup;
  username: string = "";
  msisdn: string = "123";
  password: string = "";
  errorMessageUsername: string = "";
  errorMessagePassword: string = "";
  errorMessageMsisdn: string = "";
  form: FormGroup = new FormGroup({});
  isLogin:boolean = false;
  loginSuccess: boolean = false;
  loginLoadSuccess: boolean = false;
  loginVerifyOtp: boolean = false;
  errotp:string = "";
  isCountingDown:boolean = false;
  naconfig = {};
  otpClass = {};
  otpnya: string;
  min_char_otp:boolean = false;
  errorMessageOtp:string = "";
  otpnabila: string;
  countdownOtp = null;
  signin: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.min(3) ])
  });
  hide = true;
  recaptcha_errors: boolean = false;
  get passwordInput() { return this.signin.get('password'); }

  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private router: Router,
    private apiClient: ApiClient,
   ){

  }

  bgimage:any;
  // private captchaService:NgxCaptchaService

    ngOnInit() {
      
      
      this.token = undefined;
      this.initRegForm();
      
      // this.initImgBg();

      this.otpClass = {'border-top': '0px', 'border-left':'0px','border-right':'0px', 'border-bottom':'1px solid #2F80ED', 'border-radius':'0px','width':'42px'}

      this.naconfig = {
        leftTime: 300,
        demand: false,
        format: 'mm:ss',
      };

      this.isCountingDown = true;

    }
   

    initRegForm() {
      this.registrationForm = this.fb.group({
        username: [this.username, [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}')]],
      });
    }

    toggleFieldTextType() {
      this.fieldTextType = !this.fieldTextType;
    }

    toggleRepeatFieldTextType() {
      this.repeatFieldTextType = !this.repeatFieldTextType;
    }

    get f(){
      return this.form.controls;
    }

    submit(){
    }

    login(): void{

      this.errorMessageUsername = '';
      this.errorMessageMsisdn = '';
      this.errorMessagePassword = '';
    
      this.initRegForm()
      if (this.registrationForm.valid) {
        this.LoginOtp()       
      }else{
        this.errorMessageUsername = "Invalid Email Format!";
      }

      return

    }


    resendOtp(){
      this.LoginOtp();
    }


    async LoginOtp(){
      this.loading = true;
      let req = await this.apiClient.post<loginOtpResponse>({
        endpoint: "/auth/otp",
        params:{
          email: this.username
        }
      });

      if(req.respcode === RC_SUCCESS){
         localStorage.setItem("otp_key", req.data.otp_key);
         this.loginSuccess = true; // Tandai bahwa login berhasil
         this.loginLoadSuccess = true; // Tandai bahwa login berhasil
         this.loginVerifyOtp = false; // Tandai bahwa login berhasil
         setTimeout(() => {
          this.loginVerifyOtp = true;
          this.loginLoadSuccess = false;
        }, 2000); // 2000 milidetik (2 detik)
         this.loading = false;
         this.naconfig = {
          leftTime: 180,//180
          demand: false,
          format: 'mm:ss',
        };

        this.isCountingDown = true;



      } else {
        this.loading = false
        this.errorMessageUsername = "Your email could not be found!"
      }
        this.isCountingDown = true;
        this.otp();
      this.loading = false;
    }


    public finishCount(e: Event) {
      if (e['action'] == 'done') {
        this.errotp = 'OTP Expired!';
        this.isCountingDown = false;
      }
    }

    
    async verifThisOtp(){
      if(this.min_char_otp === true){
        this.loading = true;
        var otp_key = localStorage.getItem("otp_key");
        let req = await this.apiClient.post<loginResponse>({
          endpoint: "/auth/otp_verify",
          params:{
            "otp_key": otp_key,
            "otp_code":this.otpnabila
          }
        });
  
  
  
        if(req.respcode=== RC_SUCCESS){
          localStorage.setItem("rf", req.data.refresh_token);
          localStorage.setItem("atk", req.data.token);
          localStorage.setItem("user_email", this.username);
          this.SuccessLogin.emit(true);
          localStorage.setItem("firstlog", "0");
          this.loading = false
          localStorage.setItem("otp_key", "");
          this.router.navigate(['/pages/orders/market-place'])
          .then(() => {
            window.location.reload();
          });
        } else {
          this.loading = false
          this.errorMessageOtp = "invalid OTP. Please enter correct OTP number!";
        }
      }else{
        this.errorMessageOtp = "Please enter the complete OTP!";
      }
      
    }


    onOtpChange(otpnya) {
      if (otpnya.length === 6) {
        this.errorMessageOtp = "";
        this.min_char_otp = true;
        this.otpnabila = otpnya;
      }else{
        this.min_char_otp = false;
      }
    }


   
    otpCountdown(){
    var otp_time = 181
    var countDownDate = new Date().getTime() + (otp_time * 1000);
    this.countdownOtp = setInterval(function() {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (minutes < 10) { minutes = 0 + minutes; }
      if (seconds < 10) { seconds = 0 + seconds; }
      $("#label-otp-timer").html(minutes+":"+seconds)

      // if expired
      if (distance < 0) {
        clearInterval(this.countdownOtp);
        // $("#label-otp-timer").html("00.00")
        $("#label-otp-timer").hide();
        $('#label-otp-resend-inactive').hide();
        $('#label-otp-resend-active').show();
      }
    }, 1000);
    }

  otp(){
      clearInterval(this.countdownOtp);
      $("#label-otp-timer").html('')
      this.otpCountdown();
      $(".otp-form *:input[type!=hidden]:first").focus();
      let otp_fields = $(".otp-form .otp-field"),
          otp_value_field = $(".otp-form .otp-value");
      otp_fields.on("input", function (e) {
        $(this).val(
          $(this).val().toString().replace(/[^0-9]/g, "")
        );
        let opt_value = "";
        otp_fields.each(function () {
          let field_value = $(this).val();
          if (field_value != "") opt_value += field_value;
        });
        otp_value_field.val(opt_value);
    })
    .on("keyup", function (e) {
    let key = e.keyCode || e.charCode;
    console.log("keyup", key)
    if (key == 8 || key == 46 || key == 37 || key == 40) {
      // Backspace or Delete or Left Arrow or Down Arrow
      $(this).prev().focus();
    } else if (key == 38 || key == 39 || $(this).val() != "") {
      // Right Arrow or Top Arrow or Value not empty
      $(this).next().focus();
    }
    })
    .on("paste", function (e:any) {
    let paste_data = e.originalEvent.clipboardData.getData("text");
    let paste_data_splitted = paste_data.split("");
    $.each(paste_data_splitted, function (index, value) {
    });
    });
      }

    getCodeBoxElement(index:any) {
        return document.getElementById('codeBox' + index);
      }
    onKeyUpEvent(index, event) {
  
      }
      
      onFocusEvent(index) {
        for (var item = 1; item < index; item++) {
         const currentElement = this.getCodeBoxElement(item);
        }
      }

      onSuccessLogin(value){
        this.isLogin = value;
        if(this.isLogin){
          window.location.reload()
        }
    
      }

    
      handeOtpChange(value: string[]): void {
        console.log(value);
      }
    
      handleFillEvent(value: string): void {
        console.log(value);
      }

}
