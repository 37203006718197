import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-shimmer-marketplace',
  templateUrl: './shimmer-marketplace.component.html',
  styleUrls: ['./shimmer-marketplace.component.scss']
})
export class ShimmerMarketplaceComponent  {

  constructor() { }


}
