import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-confirmation-esim',
  templateUrl: './confirmation-esim.component.html',
  styleUrls: ['./confirmation-esim.component.scss']
})
export class ConfirmationEsimComponent  {
  @Input() message: string;


  constructor() { }

  // ngOnInit(): void {
  // }

}
