import { Component, Input, OnInit } from "@angular/core";
import {
  NbComponentStatus,
  NbDialogRef,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { ApiClient } from "../../services/api.client";
import { LocalDataSource } from "ng2-smart-table";
import { SmartTableData } from "../../@core/data/smart-table";
import {
  EsimReleaseEmail,
  EsimResponse,
  MappingMsisdn,
} from "../../models/list_esim";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MsiteResponse } from "../../models/login_otp_dto";
import { RC_SUCCESS } from "../../rc_const";

@Component({
  selector: "ngx-preview-esim",
  templateUrl: "./preview-esim.component.html",
  styleUrls: ["./preview-esim.component.scss"],
})
export class PreviewEsimComponent implements OnInit {
  @Input() title: string;
  @Input() data_confirmation: Array<EsimResponse>;
  @Input() status: string;
  @Input() status_bulk: string;

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  sn: string = "";
  email: string = "";
  status_pesanan = "";
  public aFormGroup: FormGroup = new FormGroup({});
  source: LocalDataSource = new LocalDataSource();

  constructor(
    private service: SmartTableData,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<PreviewEsimComponent>,
    private apiClient: ApiClient,
    private dialogService: NbDialogService,
    private formBuilder: FormBuilder
  ) {
    const data = this.service.getData();
    this.source.load(data);
  }

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      email: ["", Validators.required],
      sn: ["", Validators.required],
    });
  }

  dismiss() {
    this.ref.close();
  }

  getData() {
    this.title = "Terima";
  }

  confirm(data: any, review: any) {
    if (review === "Mapping MSISDN") {
      this.confirmMapping(this.data_confirmation[0]);
    } else if (review === "Release MSISDN") {
      this.releaseMsisdn(this.data_confirmation[0]);
    } else if (review === "Release Email") {
      this.releaseEmail(this.data_confirmation[0]);
    } else if (review === "Update SN") {
      this.UpdateTrx(data, "1");
    } else if (review === "Set Gagal") {
      this.UpdateTrx(data, "0");
    } else if (review === "Resend Email") {
      this.gethTokenMsite();
    }
  }

  onKeyUp(e: any) {
    this.email = e.target.value;
  }

  onKeyUpSN(e: any) {
    this.sn = e.target.value;
  }

  criteriaMet(): boolean {
    // Check your criteria here
    return this.email.trim() === "";
  }

  criteriaSNMet(): boolean {
    // Check your criteria here

    return this.sn.trim() === "";
  }

  async confirmMapping(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    data_bulk.forEach((element, i) => {
      list_bulk.push(element["item_id"].toString());
    });

    let params_request = {
      item_id: list_bulk,
    };

    let req = await this.apiClient.post<MappingMsisdn>({
      endpoint: "/esim/mapping-msisdn",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      // Swal.close();
      // const result = await Swal.fire({
      //   icon: "error",
      //   title: "Gagal Mapping MSISDN",
      //   text: req.msg,
      //   buttonsStyling: true,
      //   allowOutsideClick: false,
      //   confirmButtonText: "Ok",
      // });

      // if (result.isConfirmed) {
      //   this.dismiss();
      // }
      if (typeof req.data === "string") {
        const result = await Swal.fire({
          icon: "error",
          title: "Gagal Mapping MSISDN",
          text: req.data,
          buttonsStyling: true,
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });

        if (result.isConfirmed) {
          this.dismiss();
        }
      } else {
        const result = await Swal.fire({
          icon: "error",
          title: "Gagal Mapping MSISDN",
          text: req.msg,
          buttonsStyling: true,
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });

        if (result.isConfirmed) {
          this.dismiss();
        }
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Mapping MSISDN",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  showToast(status: NbComponentStatus, message: any) {
    this.toastrService.show(status, `Success`, { status });
  }

  reset() {
    this.selectedChannel = [];
    this.selectedOpe = [];
    this.selectedExpe = [];
    this.selectedService = [];
    this.selectedStatus = [];
  }

  async releaseEmail(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    list_bulk.push(data_bulk[0]["msisdn"].toString());

    let params_request = {
      msisdn: list_bulk,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/esim/release_email",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Release Email",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Release Email",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async resendEmail(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    list_bulk.push(data_bulk[0]["msisdn"].toString());

    let params_request = {
      invoice_number: data_bulk[0]["invoice_number"],
      email: this.email,
      msisdn: data_bulk[0]["msisdn"],
    };

    let req = await this.apiClient.postTokenMsite<MsiteResponse>({
      endpoint: "/msite/resend_email",
      params: params_request,
    });
    if (req.rc != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Resend Email",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Resend Email",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async gethTokenMsite() {
    let req = await this.apiClient.postMsite<MsiteResponse>({
      endpoint: "/msite/trx_token",
    });

    if (req.rc === RC_SUCCESS) {
      localStorage.setItem("token_msite", req.data.token);
      this.resendEmail(this.data_confirmation[0]);
    } else {
      // this.loading = false
      // this.errorMessageOtp = "invalid OTP. Please enter correct OTP number!";
    }
  }

  async UpdateTrx(data_bulk: any, status_trx: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    let data_trx = {
      item_id: data_bulk[0]["item_id"].toString(),
      status_sku: data_bulk[0]["esim_status"],
      order_id: data_bulk[0]["item_id"],
      status: status_trx,
      sn: this.sn,
    };
    list_bulk.push(data_trx);

    let params_request = {
      data_trx: list_bulk,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/esim/update_trx",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Update Transaksi",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Update Transaksi",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async releaseMsisdn(e: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    list_bulk.push(e[0]["item_id"].toString());

    let params_request = {
      item_id: list_bulk,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/esim/release_msisdn",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Release MSISDN",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Release MSISDN",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }
}
