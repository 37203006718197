<nb-card
  style="max-height: 500px"
  [ngStyle]="title === 'Update SN' ? { width: '500px' } : {}"
>
  <nb-card-header style="padding-top: 12px; padding-bottom: 12px">
    <div class="row">
      <div
        class="col-md-10"
        style="font-size: 24px; margin-top: 17px; font: Poppins"
      >
        {{ title }}
      </div>
      <div class="col-sm-2" style="padding-left: 40px; text-align: right">
        <button
          type="button"
          class="btn btn-close shadow-none"
          aria-label="Close"
          (click)="dismiss()"
        >
          &times;
        </button>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="title !== 'Update SN'" class="row">
      <div class="col-md-12">
        <div *ngIf="data_confirmation[0]; else elseBlock">
          <div class="card-body position-relative" style="overflow: auto">
            <!-- <p> Total : {{ totaldata }} </p> -->
            <div class="row">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <th>No</th>
                    <th>Request Date</th>
                    <th>No Invoice/Pesanan</th>
                    <th>No Sub Invoice/Pesanan</th>
                    <th>Store Name</th>
                    <th>SKU</th>
                    <th>Product</th>
                    <th>Amount (Rp)</th>
                  </thead>
                  <tbody>
                    <!-- <tr *ngFor="let e of data | paginate: { itemsPerPage: count, currentPage: p, totalItems:totaldata }; let i = index let odd = odd" (click)="addThisPersonToArray(e,  $event.target.checked)" [ngClass]="odd ? 'odd_col' : 'even_col'">
                  <td>{{(i+1)*i}}</td> -->
                    <tr *ngFor="let e of data_confirmation[0]; let i = index">
                      <td>{{ i + 1 }}</td>
                      <td>{{ e.order_date }}</td>
                      <td>{{ e.invoice_number }}</td>
                      <td>{{ e.sub_invoice_number }}</td>
                      <td>{{ e.shop_name }}</td>
                      <td>{{ e.sku }}</td>
                      <td>{{ e.product }}</td>
                      <td>{{ e.item_amount | currency : "Rp. " }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <div>
            <ngx-tree-grid></ngx-tree-grid>
          </div> -->
            </div>
          </div>
        </div>
        <ng-template #elseBlock>No record found</ng-template>
      </div>
    </div>
    <div *ngIf="title === 'Update SN'" class="row">
      <div class="col-md-12">
        <form
          #userform="ngForm"
          [formGroup]="aFormGroup"
          (ngSubmit)="onSubmit()"
        >
          <div
            style="
              color: var(--black-gray, #363740);
              text-align: center;
              /* Medium - 16 */
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            Silahkan masukkan SN
          </div>
          <br /><br />
          <!-- <div class="row">
            <div class="col-md-12">
              <nb-select placeholder="Pilih Alasan Pembatalan" [(selected)]="selectedReason" style="min-width: 320px;">
                <nb-option  *ngFor="let item of reason" [value]="item.id">{{item.name}}</nb-option>
              </nb-select>
            </div>
          </div> -->

          <nb-form-field>
            <input
              placeholder="Nomor SN"
              formControlName="sn"
              [(ngModel)]="sn"
              (keyup)="onKeyUpSN($event)"
              [class.is-invalid]="sn?.invalid && sn?.touched"
              nbInput
            />
            <small class="text-danger" *ngIf="sn?.invalid && sn?.touched"
              >SN is required</small
            >
          </nb-form-field>
          <br /><br />

          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], title)"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #28a745; max-width: 324px; color: #ffffff"
          >
            SET SUKSES
          </button>
          &nbsp;&nbsp;
          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], 'Set Gagal')"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #eb5757; max-width: 324px; color: #ffffff"
          >
            SET GAGAL
          </button>
        </form>
      </div>
    </div>

    <!-- <div *ngIf="title === 'Update SN'" class="row">
      <div class="col-md-12">
        <form
          #userform="ngForm"
          [formGroup]="aFormGroup"
          (ngSubmit)="onSubmit()"
        >
          <div
            style="
              color: var(--black-gray, #363740);
              text-align: center;
              /* Medium - 16 */
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            Silahkan masukkan SN
          </div>
          <br /><br />

          <nb-form-field>
            <input
              placeholder="Nomor SN"
              formControlName="sn"
              [(ngModel)]="sn"
              (keyup)="onKeyUpSN($event)"
              [class.is-invalid]="sn?.invalid && sn?.touched"
              nbInput
            />
            <small class="text-danger" *ngIf="sn?.invalid && sn?.touched"
              >SN is required</small
            >
          </nb-form-field>
          <br /><br />

          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], title)"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #28a745; max-width: 324px; color: #ffffff"
          >
            SET SUKSES
          </button>
          &nbsp;&nbsp;
          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], 'Set Gagal')"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #eb5757; max-width: 324px; color: #ffffff"
          >
            SET GAGAL
          </button>
        </form>
      </div>
    </div> -->

    <div *ngIf="title === 'Input MSISDN'" class="row">
      <div class="col-md-12">
        <form
          #userform="ngForm"
          [formGroup]="aFormGroup"
          (ngSubmit)="onSubmit()"
        >
          <div
            style="
              color: var(--black-gray, #363740);
              text-align: center;
              /* Medium - 16 */
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            Silahkan masukkan MSISDN
          </div>
          <br /><br />
          <!-- <div class="row">
            <div class="col-md-12">
              <nb-select placeholder="Pilih Alasan Pembatalan" [(selected)]="selectedReason" style="min-width: 320px;">
                <nb-option  *ngFor="let item of reason" [value]="item.id">{{item.name}}</nb-option>
              </nb-select>
            </div>
          </div> -->

          <nb-form-field>
            <input
              placeholder="Nomor SN"
              formControlName="sn"
              [(ngModel)]="sn"
              (keyup)="onKeyUpMsisdn($event)"
              [class.is-invalid]="sn?.invalid && sn?.touched"
              nbInput
            />
            <small class="text-danger" *ngIf="sn?.invalid && sn?.touched"
              >SN is required</small
            >
          </nb-form-field>
          <br /><br />

          <button
            *ngIf="title === 'Input MSISDN'"
            type="button"
            (click)="confirm(data_confirmation[0], title)"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #28a745; max-width: 324px; color: #ffffff"
          >
            Input MSISDN
          </button>
        </form>
      </div>
    </div>

    <div *ngIf="title === 'Resend Email'" class="row">
      <div class="col-md-12">
        <form
          #userform="ngForm"
          [formGroup]="aFormGroup"
          (ngSubmit)="onSubmit()"
        >
          <div
            style="
              color: var(--black-gray, #363740);
              text-align: center;
              /* Medium - 16 */
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
          >
            Silahkan masukkan email
          </div>
          <br /><br />
          <!-- <div class="row">
            <div class="col-md-12">
              <nb-select placeholder="Pilih Alasan Pembatalan" [(selected)]="selectedReason" style="min-width: 320px;">
                <nb-option  *ngFor="let item of reason" [value]="item.id">{{item.name}}</nb-option>
              </nb-select>
            </div>
          </div> -->

          <nb-form-field>
            <input
              placeholder="Email"
              formControlName="email"
              (keyup)="onKeyUp($event)"
              [(ngModel)]="email"
              [class.is-invalid]="email?.invalid && email?.touched"
              nbInput
            />
            <small class="text-danger" *ngIf="email?.invalid && email?.touched"
              >Email is required</small
            >
          </nb-form-field>
          <br /><br />

          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], title)"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #28a745; max-width: 324px; color: #ffffff"
          >
            SET SUKSES
          </button>
          &nbsp;&nbsp;
          <button
            *ngIf="title === 'Update SN'"
            type="button"
            (click)="confirm(data_confirmation[0], 'Set Gagal')"
            [disabled]="criteriaSNMet()"
            class="btn btn-bg-blue pull-right"
            style="background-color: #eb5757; max-width: 324px; color: #ffffff"
          >
            SET GAGAL
          </button>
        </form>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button
      *ngIf="title === 'Mapping MSISDN'"
      type="button"
      (click)="confirm(data_confirmation[0], title)"
      class="btn btn-bg-blue"
      style="background-color: #eb5757; color: #fff"
    >
      Mapping MSISDN
    </button>
    <button
      *ngIf="title === 'Release Email'"
      type="button"
      (click)="confirm(data_confirmation[0], title)"
      class="btn btn-bg-blue"
      style="background-color: #eb5757; color: #fff"
    >
      Release Email
    </button>
    <button
      *ngIf="title === 'Resend Email'"
      [disabled]="criteriaMet()"
      type="button"
      (click)="confirm(data_confirmation[0], title)"
      class="btn btn-bg-blue"
      style="background-color: #eb5757; color: #fff"
    >
      Resend Email
    </button>
    <button
      *ngIf="title === 'Release MSISDN'"
      type="button"
      (click)="confirm(data_confirmation[0], title)"
      class="btn btn-bg-blue"
      style="background-color: #eb5757; color: #fff"
    >
      Release MSISDN
    </button>
    <!-- <button
      *ngIf="title === 'Update SN'"
      type="button"
      (click)="confirm(title)"
      [disabled]="userform.form.invalid"
      class="btn btn-bg-blue pull-right"
      style="background-color: #eb5757; max-width: 324px; color: #ffffff"
    >
      Update SN
    </button> -->
  </nb-card-footer>
</nb-card>
