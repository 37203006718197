import { Component, Input, OnInit } from "@angular/core";
import {
  NbComponentStatus,
  NbDialogRef,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { ApiClient } from "../../services/api.client";
import { LocalDataSource } from "ng2-smart-table";
import { SmartTableData } from "../../@core/data/smart-table";
import {
  EsimReleaseEmail,
  EsimResponse,
  MappingMsisdn,
} from "../../models/list_esim";
import Swal from "sweetalert2";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "ngx-preview-usim",
  templateUrl: "./preview-usim.component.html",
  styleUrls: ["./preview-usim.component.scss"],
})
export class PreviewUsimComponent implements OnInit {
  @Input() title: string;
  @Input() data_confirmation: Array<EsimResponse>;
  @Input() status: string;
  @Input() status_bulk: string;

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  sn: string = "";
  msisdn: string = "";
  status_pesanan = "";
  public aFormGroup: FormGroup = new FormGroup({});
  source: LocalDataSource = new LocalDataSource();

  constructor(
    private service: SmartTableData,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<PreviewUsimComponent>,
    private apiClient: ApiClient,
    private dialogService: NbDialogService,
    private formBuilder: FormBuilder
  ) {
    const data = this.service.getData();
    this.source.load(data);
  }

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      sn: ["", Validators.required],
    });
  }

  dismiss() {
    this.ref.close();
  }

  getData() {
    this.title = "Terima";
  }

  confirm(data: any, review: any) {
    if (review === "Mapping MSISDN") {
      this.confirmMapping(this.data_confirmation[0]);
    } else if (review === "Release MSISDN") {
      this.releaseMsisdn(this.data_confirmation[0]);
    } else if (review === "Release Email") {
      this.releaseEmail(this.data_confirmation[0]);
    } else if (review === "Update SN") {
      this.UpdateTrx(data, "1");
    } else if (review === "Set Gagal") {
      this.UpdateTrx(data, "0");
    } else if (review === "Input MSISDN") {
      this.inputMsisdn(data);
    }
  }

  onKeyUpSN(e: any) {
    this.sn = e.target.value;
  }

  onKeyUpMsisdn(e: any) {
    this.msisdn = e.target.value;
  }

  criteriaSNMet(): boolean {
    // Check your criteria here

    return this.sn.trim() === "";
  }

  async inputMsisdn(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    console.log("data_bulk", data_bulk);
    list_bulk.push(data_bulk[0]["item_id"].toString());

    let params_request = {
      sub_invoice_number: data_bulk[0]["sub_invoice_number"],
      msisdn: this.msisdn,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/usim/insert_mapped_msisdn",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Insert MSISDN",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Insert MSISDN",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async releaseEmail(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    list_bulk.push(data_bulk[0]["item_id"].toString());

    let params_request = {
      item_id: list_bulk,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/esim/release_email",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Release Email",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Release Email",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async confirmMapping(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    data_bulk.forEach((element, i) => {
      list_bulk.push(element["item_id"].toString());
    });

    let params_request = {
      item_id: list_bulk,
      email: localStorage.getItem("user_email"),
    };

    let req = await this.apiClient.post<MappingMsisdn>({
      endpoint: "/usim/mapping-msisdn",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Update Transaksi",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      if (req.data["failed_msisdn"].length == 0) {
        Swal.close();
        const result = await Swal.fire({
          title: "Sukses",
          text: "Berhasil Mapping MSISDN",
          icon: "success",
          buttonsStyling: true,
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });

        if (result.isConfirmed) {
          this.dismiss();
        }
      } else {
        var resp = req.data["failed_msisdn"];
        const itemsHtml = resp
          .map(
            (a, i) =>
              `<li style='list-style:none;'> ${a.msisdn} - ${a.msg}</li>`
          )
          .join("");

        const htmlnya = `<div><ul>${itemsHtml}</ul></div>`;

        const result = await Swal.fire({
          icon: "error",
          title: "Gagal Mapping MSISDN",
          html: htmlnya,
          buttonsStyling: true,
          allowOutsideClick: false,
          confirmButtonText: "Ok",
        });

        if (result.isConfirmed) {
          this.dismiss();
        }
      }
    }
  }

  showToast(status: NbComponentStatus, message: any) {
    this.toastrService.show(status, `Success`, { status });
  }

  reset() {
    this.selectedChannel = [];
    this.selectedOpe = [];
    this.selectedExpe = [];
    this.selectedService = [];
    this.selectedStatus = [];
  }

  async UpdateTrx(data_bulk: any, status_trx: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    let data_trx = {
      sub_invoice_number: data_bulk[0]["sub_invoice_number"],
      status: status_trx,
      sn: this.sn,
    };
    list_bulk.push(data_trx);

    let params_request = {
      data_trx: list_bulk,
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/usim/update_trx",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Update Transaksi",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Update Transaksi",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }

  async releaseMsisdn(e: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    var list_bulk = [];
    list_bulk.push(e[0]["item_id"].toString());

    let params_request = {
      item_id: list_bulk,
      email: localStorage.getItem("user_email"),
    };

    let req = await this.apiClient.post<EsimReleaseEmail>({
      endpoint: "/usim/release_msisdn",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss();
      Swal.close();
      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Release MSISDN",
        text: req.msg,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    } else {
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Release MSISDN",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        this.dismiss();
      }
    }
  }
}
