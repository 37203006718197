<nb-card>
  <div class="container-fluid" style="min-height:85vh">
    <div class="card" style="border: 0px;background-color: unset !important;">  
      <div class="row">
        <div class="title" style="padding: 30px 32px 24px 16px;">Market Place</div>
      </div>
      <div class="row">
        <div class="col-md-6" style="text-align:left; padding-bottom:5px">
          <button type="button" class="btn btn-bg-blue pull-right" style="background-color:#F2994A; max-width:114px;">
            <img src="../../../assets/icon/icon-export-csv.svg">
            Export
          </button>

        </div>
        <div class="row col-md-6" style="justify-content:end;">
              <div class="">
                      <input type="text" id="txt_product_filter" class="form-control"   name="txt_product_filter" value="" >
                      <span class="fa fa-search errspan"></span>
              </div>
              
              <input style="margin-left:10px;width: 167px; height:40px; border-radius: 8px;
              border: 1px solid var(--light-2, #E8ECFF);
              background: var(--white, #FFF);"  placeholder="Pilih Tanggal">
              <nb-rangepicker #ngmodel  format="yyyy-MM-dd"> </nb-rangepicker>
          
              <div class="pull-right" style="margin-left: 10px;">
                  <img src="../../../assets/icon/filter.svg">
              </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-md-12"><hr style="border: 1px solid #D7DAEB;"></div>
    </div>
  
    <div class="clearfix m-1"></div>
  
    <div class="row" style="margin-left:15px;">
      

      <div>
        <button class="info-box"  style="border-radius:8px;">
        <div class="info-box-content">
          <span class="info-box-text">Semua Pesanan</span>
        </div>
  
        </button>
  
      </div>
  
      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Pesanan Baru</span>
        </div>
  
      </button>
  
      </div>
  
      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Siap Dikirim</span>
        </div>
  
      </button>
  
      </div>

      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Dalam Pengiriman</span>
        </div>
  
      </button>
  
      </div>

      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Dikomplain</span>
        </div>
  
      </button>
  
      </div>

      <div>
        <button class="info-box" style="border-radius:8px;"> 
        <div class="info-box-content">
        <span class="info-box-text">Diterima</span>
        </div>
  
        </button>
  
      </div>
      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Pesanan Selesai</span>
        </div>
  
      </button>
  
      </div>

      <div>
        <button class="info-box" style="border-radius:8px;">
        <div class="info-box-content">
        <span class="info-box-text">Dibatalkan</span>
      </div>
  
        </button>
  
      </div>
    </div>
  
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body position-relative">
              
              <!-- <p> Total : {{ totaldata }} </p> -->
              <div class="row">
                <div class="table-responsive">
                <table class="table borderless table-headers">
                  <thead style="color: #E8ECFF;">
                    <th><input type="checkbox"></th>
                    <th>No</th>
                    <th>Status</th>
                    <th>Operator</th>
                    <th>Channel</th>
                    <th>No Invoice/Pesanan</th>
                    <!-- <th>Nama Produk</th> -->
                    <th>QTY</th>
                    <th>Ekspedisi</th>
                    <th>Service</th>
                    <th>Request Data</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <!-- <td>{{e.product_name}}</td> -->
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                     <td><ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading></td>
                      
                  
                      
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- <div>
                <ngx-tree-grid></ngx-tree-grid>
              </div> -->
              </div>
  
  
  
            </div>
          </div>
        </div>
      </div>

      <div class="text-right">
        <ngx-shimmer-loading [shape]="'square'" [width]="'70px'"></ngx-shimmer-loading>
      </div>
    </div>
  </nb-card>