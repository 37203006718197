import axios from "axios";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Injectable, ErrorHandler, Optional } from "@angular/core";
import { environment } from "../../environments/environment";
import { FailedRespComponent } from "../component/failed-resp/failed-resp.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";
import { loginResponse } from "../models/login_otp_dto";
import { RC_SUCCESS } from "../rc_const";
import { Observable } from "rxjs";

//==============================
// Author: Ronald H.
// Ref: https://www.bennadel.com/blog/3444-proof-of-concept-using-axios-as-your-http-client-in-angular-6-0-0.htm
//==============================

export const ApiSetting = {
  tm: 70000, //30 seconds
  SystemError: 99,
  ErrorMessage: "API Call error",
  RefreshTokenKey: "rf",
  AccessTokenKey: "atk",
  TokenMsite:
    "eyJhbGciOiJkaXIiLCJlbmMiOiJBMjU2R0NNIn0..776SfukeD2yM2WCVIh3JZg.mItUBLpgucoFXQVszDfR_DGOuFJ264cEaWinLe6W70th-su9PBFlJ4J6E3ywVmC_80zV4ms9GqqB1OpBesYDlv6OY2JYZK90nL2mDlDfzSNblkslOLFGIF-5gr_ZTkFSjsk_tQlWXaWGUSyjwjbTsnM.A-JjxUSlCIykw1rFl0ng-w",
};

export interface ParamKey {
  [key: string]: any;
}

export interface ReqObject {
  endpoint: string;
  params?: ParamKey;
}

export interface ExceptionObject {
  rc: number;
  msg: string;
}

@Injectable({
  providedIn: "root",
})
export class ApiClient {
  private axiosClient: AxiosInstance;
  private axiosClientMsite: AxiosInstance;
  private axiosClientTokenMsite: AxiosInstance;
  private errorHandler: ErrorHandler;
  public ctype: string = "application/json";
  private defaultContentType: string = "application/json";

  // constructor(errorHandler: ErrorHandler, @Optional() ctype: string=''){
  constructor(
    errorHandler: ErrorHandler,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient
  ) {
    this.errorHandler = errorHandler;
    this.axiosClient = axios.create({
      timeout: ApiSetting.tm,
      headers: {
        accept: this.ctype,
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET,POST",
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": this.ctype,
      },
    });
    this.axiosClientTokenMsite = axios.create({
      timeout: ApiSetting.tm,
      headers: {
        accept: this.ctype,
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET,POST",
        Authorization: "Bearer " + this.getTokenMsite(),
        "Content-Type": this.ctype,
      },
    });
    this.axiosClientMsite = axios.create({
      timeout: ApiSetting.tm,
      headers: {
        accept: this.ctype,
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET,POST",
        Authorization: "Bearer " + ApiSetting.TokenMsite,
        "Content-Type": this.ctype,
      },
    });
  }

  public setContentType(contentType: string): void {
    this.ctype = contentType;
  }

  public resetContentType(): void {
    this.ctype = this.defaultContentType;
  }

  public async post<T>(options: ReqObject): Promise<T> {
    try {
      var axiosResponse = await this.axiosClient.request<T>({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: options.params,
      });
      return axiosResponse.data;
    } catch (error) {
      Swal.close();

      if (error.response.data.respcode == 123) {
        this.refreshToken();
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  public async postMsite<T>(options: ReqObject): Promise<T> {
    try {
      var axiosResponse = await this.axiosClientMsite.request<T>({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: options.params,
      });
      return axiosResponse.data;
    } catch (error) {
      Swal.close();

      if (error.response.data.respcode == 123) {
        this.refreshToken();
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  public async postTokenMsite<T>(options: ReqObject): Promise<T> {
    try {
      var axiosResponse = await this.axiosClientTokenMsite.request<T>({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: options.params,
      });
      return axiosResponse.data;
    } catch (error) {
      Swal.close();

      if (error.response.data.respcode == 123) {
        this.refreshToken();
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  public async postHtml<T>(options: ReqObject): Promise<T | string> {
    try {
      var axiosResponse = await this.axiosClient.request({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: options.params,
      });
      const contentType = axiosResponse.headers["content-type"];
      if (contentType && contentType.includes("text/html")) {
        return axiosResponse.data as string;
      }
      return axiosResponse.data as T;
    } catch (error) {
      Swal.close();
      if (error.response.data.respcode == 123) {
        this.refreshToken();
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  public async postDownloadFile<T>(
    options: ReqObject
  ): Promise<AxiosResponse<T>> {
    try {
      var axiosResponse = await this.axiosClient.request({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: options.params,
        responseType: "blob",
      });
      return axiosResponse;
    } catch (error) {
      Swal.close();

      if (error.response.data.respcode == 123) {
        this.router.navigate(["/login"]);
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  //IMPORT DATA ORDER, MSISDN-ESIM, ACT-CODE SMARTFREN
  public async postFile<T>(formData: any, options: ReqObject): Promise<T> {
    const requestConfig: AxiosRequestConfig = {
      params: options.params,
      headers: {
        //'accept': 'application/json',
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Allow-Methods": "GET,POST",
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": `multipart/form-data`,
      },
    };

    try {
      var axiosResponse = await this.axiosClient.request<T>({
        method: "post",
        url: environment.api_host + options.endpoint,
        data: formData,
        ...requestConfig,
      });
      return axiosResponse.data;
    } catch (error) {
      Swal.close();

      if (error.response.data.respcode == 123) {
        this.refreshToken();
      } else {
        this.handleFailedAxios(error.response.data.msg, FailedRespComponent);
      }
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  uploadFile(
    file: File,
    partner_code: string,
    is_postpaid: boolean,
    location_id: string,
    box_no: string
  ) {
    const url = environment.api_host + "/upload/import-product-sp";

    // Create headers with the necessary information
    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file, "template.xlsx");

    // Append query parameters to the URL
    const params = {
      operator_id: partner_code["partner_code"].split("#")[1],
      is_postpaid: is_postpaid,
      partner_code: partner_code["partner_code"].split("#")[0],
      email: localStorage.getItem("user_email"),
      location_id: location_id,
      box_no: box_no,
    };

    // Make the HTTP POST request
    return this.http.post(url, formData, { headers, params });
  }

  uploadFileEsim(
    file: File,
    partner_code: string,
    is_postpaid: boolean,
    warehouse: string,
    box_no: string
  ) {
    const url = environment.api_host + "/upload/import-product-sp";

    // Create headers with the necessary information
    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file, "template.xlsx");

    // Append query parameters to the URL
    const params = {
      operator_id: warehouse["os_id"],
      is_postpaid: is_postpaid,
      partner_code: partner_code["partner_code"],
      email: localStorage.getItem("user_email"),
      location_id: warehouse["location_id"],
      box_no: box_no,
    };

    // Make the HTTP POST request
    return this.http.post(url, formData, { headers, params });
  }

  uploadFileMsisdn(file: File) {
    const url = environment.api_host + "/upload/insert_mapped_msisdn";

    // Create headers with the necessary information
    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file, "template.xlsx");

    // Append query parameters to the URL

    // Make the HTTP POST request
    return this.http.post(url, formData, { headers });
  }

  uploadFileImage(file: File, device_product_id: string) {
    const url = environment.api_host + "/upload/import-product-sp";

    // Create headers with the necessary information
    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("files", file, "template.xlsx");

    // Append query parameters to the URL
    const params = {
      device_product_id: device_product_id,
    };

    // Make the HTTP POST request
    return this.http.post(url, formData, { headers, params });
  }

  uploadImageDevice(file: File, device_product_id: string) {
    const url = environment.api_host + "/device/upload-images";

    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });
    const formData: FormData = new FormData();

    formData.append("files", file);

    const params = {
      device_product_id: device_product_id,
    };

    // const req = new HttpRequest('POST', `${environment.api_host}/upload`, formData, {
    //   params: params,
    //   reportProgress: true,
    //   headers,
    // });

    return this.http.post(url, formData, { headers, params });
  }

  uploadFileSku(file: File, shop_id: string) {
    const url = environment.api_host + "/sku/import";

    // Create headers with the necessary information
    const headers = new HttpHeaders({
      accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "GET,POST",
      Authorization: "Bearer " + this.getToken(),
      // 'Content-Type': `multipart/form-data`,
    });

    // Create a FormData object and append the file to it
    const formData = new FormData();
    formData.append("file", file, "template.xlsx");

    // Append query parameters to the URL
    const params = {
      shop_id: shop_id["shop_id"],
      // partner_code: partner_code['partner_code'].split('#')[0],
      // email: localStorage.getItem('user_email'),
    };

    // Make the HTTP POST request
    return this.http.post(url, formData, { headers, params });
  }

  private getToken() {
    let token = localStorage.getItem(ApiSetting.AccessTokenKey);
    return token;
  }

  private getTokenMsite() {
    let msite_token = localStorage.getItem("token_msite");
    return msite_token;
  }

  public async get<T>(options: ReqObject): Promise<T> {
    try {
      const headers = {
        accept: this.ctype,
        Authorization: "Bearer " + this.getToken(),
        "Content-Type": this.ctype, // Menggunakan this.ctype sebagai Content-Type
      };

      var axiosResponse = await this.axiosClient.request<T>({
        method: "get",
        url: environment.api_host + options.endpoint,
        data: options.params,
        headers: headers,
      });
      return axiosResponse.data;
    } catch (error) {
      this.handleFailedAxios(error.message, FailedRespComponent);
      return Promise.reject(this.apiClientExeption(error));
    }
  }

  private apiClientExeption(error: any): ExceptionObject {
    this.errorHandler.handleError(error);
    return {
      rc: ApiSetting.SystemError,
      msg: ApiSetting.ErrorMessage,
    };
  }

  public handleFailedAxios(res, cp) {
    let inq = {
      resp: res,
    };

    const SuccessModal = this.modalService.open(cp);
    // SuccessModal.componentInstance.user = res;
    SuccessModal.result.then((result) => {});
  }

  async refreshToken() {
    // this.loading = true;
    var refresh_token = localStorage.getItem("rf");
    var token = localStorage.getItem("atk");
    let req = await this.post<loginResponse>({
      endpoint: "/auth/refresh_token",
      params: {
        token: token,
        refresh_token: refresh_token,
      },
    });

    if (req.respcode === RC_SUCCESS) {
      localStorage.setItem("rf", req.data.refresh_token);
      localStorage.setItem("atk", req.data.token);
      localStorage.setItem("addm", "0");
      // this.router.navigate(['/pages/orders/market-place']);
      this.router.navigate(["/pages/orders/market-place"]).then(() => {
        window.location.reload();
      });
    } else {
      // this.loading = false
      // this.errorMessageOtp = "invalid OTP. Please enter correct OTP number!";
    }
  }
}
