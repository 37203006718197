import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { listOrderResponse } from "../../models/list_order_dto";
import { ApiClient } from "../../services/api.client";
import { ProdukKosongComponent } from "../produk-kosong/produk-kosong.component";
import { FormGroup, FormControl, FormArray, Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "ngx-tolakpesanan",
  templateUrl: "./tolakpesanan.component.html",
  styleUrls: ["./tolakpesanan.component.scss"],
})
export class TolakpesananComponent implements OnInit {
  @Input() title: string;
  @Input() data: Array<listOrderResponse>;
  @Input() status: string;
  @Input() notes: string;

  submitted = false;
  public aFormGroup: FormGroup = new FormGroup({});
  data_confirmation = [];
  selectedReason = "";
  public reasons = [
    {
      id: 1,
      name: "Stok Produk Kosong",
    },
    {
      id: 2,
      name: "Pembeli mengajukan pembatalan",
    },
    {
      id: 3,
      name: "Toko Sedang tutup",
    },
    {
      id: 4,
      name: "Lainnya",
    },
  ];

  constructor(
    protected ref: NbDialogRef<TolakpesananComponent>,
    private apiClient: ApiClient,
    private dialogService: NbDialogService,
    private formBuilder: FormBuilder
  ) {}

  dismiss() {
    this.ref.close();
  }

  onSubmit() {
    // console.log(this.userModel);

    this.submitted = true;
  }

  getData() {
    console.log("data confirmation", "a");
  }

  ngOnInit(): void {
    this.getData();
    this.aFormGroup = this.formBuilder.group({
      reason: ["", Validators.required],
    });
  }

  getInputType() {
    return "text";
  }

  get reason() {
    console.log("jenis pesan", this.aFormGroup.get("reason"));
    return this.aFormGroup.get("reason");
  }

  changeWebsite(e: any) {
    console.log("test", e.value);
    this.reason?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  confirm() {
    this.dismiss();
    console.log("data", this.aFormGroup.get("reason").value);
    var alasan_tolak = this.aFormGroup.get("reason").value;
    if (alasan_tolak == 1) {
      this.dialogService.open(ProdukKosongComponent, {
        context: {
          reason: alasan_tolak,
          data_confirmation: this.data,
        },
        closeOnEsc: false,
      });
    } else {
      this.rejectOrder();
    }
  }

  async rejectOrder() {
    let params_reject = {
      empty_products: [],
      order_id: this.data_confirmation[0]["order_items"],
      os_type: this.data_confirmation[0]["os_type"],
      reason_code: 0,
      shop_close_end_date: "",
      shop_close_note: "",
      shop_id: this.data_confirmation[0]["shop_id"],
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/nack",
      params: params_reject,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      const htmlnya = `<div>${req.msg}</div></br><div>${req.data}</div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Terima Pesanan",
        text: req.msg,
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Terima Pesanan",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }
  }
}
