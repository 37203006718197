import { Component, OnInit, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import {NgbDateStruct,  NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
const now = new Date();
@Component({
  selector: 'ngx-datepicker-popup',
  templateUrl: './datepicker-popup.component.html',
  styleUrls: ['./datepicker-popup.component.scss']
})
export class DatepickerPopupComponent implements OnInit {
  @Input() name:String;
  @Input() def_date:String;
  @Output() dateSelectedEvent = new EventEmitter()
  @Output() lempar = new EventEmitter(); //sample
  @ViewChild('NgbdDatepicker') d: NgbDateStruct;

  model: NgbDateStruct;
  startDate = ''
	endDate = ''
	showEndDate;W
	date = null;
	dateRange: [Date, Date];
	today = new Date();
  constructor (private calender: NgbCalendar, public datepipe: DatePipe) {}

  ngOnInit(): void {
    let format_date = new Date();
    this.model={year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    //this.model =
   // alert(this.model)
    this.onChange
  }

  onChange(result): void {
    //this.dateSelectedEvent.emit(result);
    let isi = result['year'].toString()+'-'+result['month'].toString()+'-'+result['day'].toString()
    let format_date = new Date(isi);
    let latest_date =this.datepipe.transform(format_date, 'yyyy-MM-dd');
    this.lempar.emit(latest_date);
	}

}
