<div class="container-fluid">
  <div class="row no-gutter background">
      <!-- The image half -->
      <div class="col-md-7 d-none d-md-flex bg-image"></div>
      <!-- The content half -->
      <div class="col-md-5">
          <div class="login d-flex align-items-center py-5">
                  <div class="row">
                      <input type="hidden" id="txt_msg" />
                      <div class="col-md-10 small-container-login">
                          <div class="inside-login-container text-center">
                              <div id="div-logo-login">
                                  <img *ngIf="!loginVerifyOtp" src="../../../assets/icon/logo-nodeos.svg" width="309" height="64" style="margin-top: 15%; margin-bottom:10%"/>
                                  <img *ngIf="loginVerifyOtp" src="../../../assets/icon/logo-otp.svg" width="120" />
                              </div>

                          <form id="div-form-login" #form="ngForm"  *ngIf="!loginVerifyOtp" >
                              <div class="d-flex p-2"></div>
                              <div class="form-group text-center">
                                  <label><span class="label-login1">Log In</span></label>
                                  <div class="d-flex p-1"></div>
                                  <label><span class="label-login2">Kami akan mengirimkan OTP ke alamat email anda</span></label>
                              </div>

                              <div class="form-group text-left">
                                  <label><span class="label-login3">Email</span></label>
                                  <input type="email"
                                  class="form-control fc"
                                  [ngClass]="{
                                    'invalid': userinValid
                                  }"
                                  id="username"
                                  name="username"
                                  [(ngModel)]="username"
                                  placeholder="Email"
                                  required>
                              </div>
                              <div class="d-flex justify-content-left">
                                <p class="left-center merah">{{ errorMessageUsername }}</p>
                            </div>

                              <div class="d-flex p-3"></div>
                              <button type="submit" (click)="login()" class="btn btn-login" id="btn-login">
                                  Get OTP
                                  <img src="../../../assets//icon/loading-small.gif" id="img-loading-get-otp" *ngIf="loading" />
                              </button>
                              <div class="d-flex p-1"></div>
                            </form>
                         <!-- Verify OTP -->
                          <div id="div-form-otp" *ngIf="loginVerifyOtp">
                              <div class="form-group text-center">
                                  <label><span class="label-login1">Verifikasi OTP</span></label>
                                  <div class="d-flex p-1"></div>
                                  <label><span class="label-login2">Masukkan Nomor OTP yang sudah dikirim ke <span id="span-email-otp"></span></span></label>
                              </div>

                              <!-- <div class="form-group"> -->
                                  <div class="otp-form"> 
                                  <ng-otp-input (onInputChange)="onOtpChange($event)"
                                  #ngOtpInput
                                  class="otp-field"
                                  style="border: 1px;"
                                  (keyup.enter)="onOtpChange($event)"
                                  [config]="{length:6}">
                              </ng-otp-input>
                              </div>
                              <div class="row m_top_10">
                                <div class="col-md-1"></div>
                                <div class="col-md-10 merah tengah" style="margin-bottom: 5%;">
                                    {{ errorMessageOtp }}
                                </div>
                                <div class="col-md-1"></div>
                              </div>

                             <div class="form-group text-center">
                                 <label>
                                     <span class="label-login2">Tidak Terima OTP? </span>
                                     <span id="label-otp-resend-inactive" class="label-otp-resend-inactive">Kirim Ulang</span>&nbsp;
                                     <a (click)="resendOtp()" id="label-otp-resend-active" class="label-otp-resend-active" style="display:none"><span>Kirim Ulang</span></a>
                                     <span id="label-otp-timer" class="label-otp-resend-active"> 03:00</span>
                                 </label>
                                 <label><span class="label-login2">Harap periksa di inbox/junk email anda</span></label>
                              </div>

                              <div class="d-flex p-1"></div>
                              <button type="submit" (click)="verifThisOtp()" id="btn-send-otp" class="btn btn-login" [ngClass]="min_char_otp ? 'showthisbutt' : 'hidethisbutt'">
                                  Verifikasi & Proses
                                  <img src="../../../assets/icon/loading-small.gif" id="img-loading-verify-otp" style="display:none;" />
                              </button>

                             <div class="d-flex p-1"></div>
                             <div id="div-msg-otp" style="display:none;">
                                 <div class="form-group text-center">
                                     <label><span id="label-login-error" class="label-login-error"><strong>OTP yang anda masukkan salah atau kadaluarsa!</strong></span></label>
                                  </div>
                             </div>
                      </div>


                      </div>
                      </div>
                  </div>
              </div><!-- End -->

          </div>
      </div><!-- End -->
  </div>
