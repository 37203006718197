import { Component, Input, OnInit } from "@angular/core";
import { listOrderResponse } from "../../models/list_order_dto";
import { ApiClient } from "../../services/api.client";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import Swal from "sweetalert2";

@Component({
  selector: "ngx-produk-kosong",
  templateUrl: "./produk-kosong.component.html",
  styleUrls: ["./produk-kosong.component.scss"],
})
export class ProdukKosongComponent {
  @Input() data_confirmation: Array<listOrderResponse>;
  @Input() reason: number;
  selectedIds: string[] = []; // Properti untuk menyimpan ID yang diceklis
  selectedTrxIds: string[] = []; // Properti untuk menyimpan trx_id yang diceklis
  search: string = "";
  produk_kosong = [];
  constructor(
    private apiClient: ApiClient,
    protected ref: NbDialogRef<ProdukKosongComponent>,
    private toastrService: NbToastrService
  ) {}

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnInit() {
    this.getBookDetails();
    console.log("selected", this.data_confirmation);
  }

  getBookDetails() {
    console.log("aa", this.data_confirmation[0]["order_items"][0]);
  }

  checkAllCheckBox(ev: any) {
    var b = this.data_confirmation[0]["order_items"].forEach(
      (x) => (x.checked = ev.target.checked)
    );
    console.log("b", b);
  }

  isAllCheckBoxChecked() {
    var a = this.data_confirmation[0]["order_items"].every((p) => p.checked);
    return a;
  }

  dismiss() {
    this.ref.close();
  }

  async rejectOrder() {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("data_bulk", this.data_confirmation);
    var list_bulk = [];
    this.selectedTrxIds.forEach((element, i) => {
      console.log("oo", element["product_id"]);
      this.produk_kosong.push({
        model_id: element["model_id"],
        item_id: element["product_id"],
      });
    });

    console.log("list_bug", this.produk_kosong);

    let params_reject = {
      empty_products: this.produk_kosong,
      order_id: this.data_confirmation[0]["order_id"],
      os_type: this.data_confirmation[0]["os_type"],
      reason_code: this.reason,
      shop_close_end_date: "",
      shop_close_note: "",
      shop_id: this.data_confirmation[0]["shop_id"],
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/nack",
      params: params_reject,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      const htmlnya = `<div>${req.msg}</div></br><div>${req.data}</div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Tolak Pesanan",
        text: req.msg,
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Tolak Pesanan",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }
  }

  addThisPersonToArray(e: any, a) {
    this.data_confirmation[0]["order_items"]
      .filter((x) => x.checked)
      .map((x) => {
        console.log(x);
      });
  }

  checkboxChanged(event: any, item: any): void {
    item.isChecked = event.target.checked;
    console.log("item checked", item.isChecked);

    if (item.isChecked) {
      this.selectedTrxIds.push(item);
      console.log("checked", this.selectedTrxIds);
    } else {
      // Jika checkbox tidak diceklis, kurangi nilai item['paid_amount'] dari saldo_deposit
      const index = this.selectedTrxIds.indexOf(item);
      if (index !== -1) {
        this.selectedTrxIds.splice(index, 1);
        console.log("unchecked", this.selectedTrxIds);
      }
    }
  }
}
