<nb-card style="overflow: auto; max-width: 1485px; max-height: 800px">
  <div *ngFor="let e of list[0]; let i = index">
    <div class="row" style="margin-left: -14px; margin-right: 20px">
      <nb-card-header style="width: 100%">
        <div
          class="col-md-12"
          style="
            font-size: 20px;
            font-family: Poppins;
            font-weight: 600;
            color: var(--gray-7, #363740);
          "
        >
          {{ e.invoice_number }}
        </div>
      </nb-card-header>
    </div>

    <nb-card-body>
      <div style="padding-left: 24px; padding-right: 24px">
        <div class="row">
          <div class="col-md-12">
            <div
              style="
                font-size: 24px;
                margin-top: 17px;
                font: Poppins;
                font-weight: bold;
                margin-bottom: 16px;
              "
            >
              History Mapping
            </div>
            <table class="table table-borderless table-responsive-sm">
              <tbody>
                <tr>
                  <th style="width: 100px; padding-left: 0">Product Name</th>
                  <th
                    style="width: 10px; padding-left: 5px; padding-right: 5px"
                  >
                    :
                  </th>
                  <td style="width: 70px; padding-left: 0">
                    {{ e.product }}
                  </td>
                </tr>
                <tr>
                  <th style="width: 100px; padding-left: 0">Store Name</th>
                  <th
                    style="width: 10px; padding-left: 5px; padding-right: 5px"
                  >
                    :
                  </th>
                  <td style="width: 70px; padding-left: 0">
                    {{ e.shop_name }}
                  </td>
                </tr>
                <tr>
                  <th style="width: 100px; padding-left: 0">Sub Invoice</th>
                  <th
                    style="width: 10px; padding-left: 5px; padding-right: 5px"
                  >
                    :
                  </th>
                  <td style="width: 70px; padding-left: 0">
                    {{ e.sub_invoice_number }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style="padding-left: 12px; padding-right: 12px">
          <div
            class="row"
            style="
              padding: 10px;
              border-radius: 8px;
              border: 1px solid var(--gray-3, #d7daeb);
              background: var(--white, #fff);
            "
          >
            <div class="col-md-12">
              <div
                style="
                  font-size: 20px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                Detail
              </div>
              <div class="table-wrapper-scroll-y my-custom-scrollbar">
                <table class="table table-bordered">
                  <thead
                    style="opacity: 0.20000000298023224; background: #d9d9d9"
                  >
                    <th>No</th>
                    <th>Waktu</th>
                    <th>No Ref</th>
                    <th>ID Transaksi</th>
                    <th>MSISDN</th>
                    <th>Pesan</th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let b of data_confirmation; let c = index">
                      <td>{{ c + 1 }}</td>
                      <td>{{ b.created_datetime }}</td>
                      <td>{{ b.no_ref }}</td>
                      <td>{{ b.trxid }}</td>
                      <td>{{ b.log_request.msisdn }}</td>
                      <td>
                        <ul style="list-style: none">
                          <!-- <li style="list-style: none"> -->
                          {{
                            b.log_response.message
                          }}
                          <!-- </li> -->
                        </ul>
                      </td>
                      <!-- <td>{{e.injection[i].msisdn}} ?? '-'</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
  </div>
</nb-card>
