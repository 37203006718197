<nb-card style="max-width:428px;">
  <nb-card-header style="padding-top: 12px; padding-bottom: 12px;">
    <div class="row">
      <div class="col-md-12" style="color: var(--black-gray, #363740);
      text-align: center;
      /* Semi Bold - 18 */
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;">Konfirmasi Pickup</div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div style="color: var(--black-gray, #363740);
    text-align: center;
    /* Medium - 16 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;">Konfirmasi Pickup pesanan, silahkan lakukan pengecekan kembali.</div>
    <br/><br/>
  </nb-card-body>

  <nb-card-footer>
    <div class="row">
    <div class="col-md-8" style="text-align: left;">
      <button *ngIf="status_bulk === 'bulk'" type="button" (click)="bulkrequestPickup(data_confirmation[0])" class="btn btn-bg-blue pull-right" style="background-color:#F2994A; max-width:324px; color:#ffffff">
        <img src="../../../assets/icon/icon-export-csv.svg">
        Pickup Pesanan ({{data_confirmation[0].length}})
      </button>
      <button *ngIf="status_bulk === 'none'" type="button" (click)="requestPickup()" class="btn btn-bg-blue pull-right" style="background-color:#F2994A; max-width:324px; color:#ffffff">
        <img src="../../../assets/icon/icon-export-csv.svg">
        Pickup Pesanan
      </button>
    </div>

    <div class="col-md-4" style="text-align: right;">
      <button type="button" class="btn btn-bg-blue pull-right" (click)=dismiss() style="background-color:transparent; border-color: #007bff; max-width:114px; color:#007bff">
        <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
          Kembali
      </button>
    </div>
    </div>
  </nb-card-footer>
</nb-card>