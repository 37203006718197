import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <div class="row">
  <div class="col-lg-12 pl50">
      <footer class="main-footer color-gray-2 body-regular-12pt bgputih">
          <strong>Copyright &copy; 2023 <a target="_blank" href="https://www.narindo.com/#/">PT. Narindo Solusi Telekomunikasi</a>.</strong>
          All rights reserved.
        </footer>
  </div>
  `,
})
export class FooterComponent {
}
