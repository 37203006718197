
<div id="divloading" *ngIf="loading">
<img src="../assets/images/loading.gif" class="img_div_load">
</div>
<!-- <ngx-loading></ngx-loading> -->


<div class="" *ngIf="!isLogin">
    <ngx-login-otp (onSuccessLogin)="onSuccessLogin($event)"></ngx-login-otp>
</div>



    <div class="row utama"  *ngIf="isLogin">
        <div class="col-lg-12 rgbar">
            
            <div class="row">
                <div class="col-lg-12 ruterotlet">
                    <router-outlet></router-outlet>
                    <!-- <ngx-pages></ngx-pages> -->
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-lg-12 pl50">
                    <footer class="main-footer color-gray-2 body-regular-12pt bgputih">
                        <strong>Copyright &copy; 2023 <a target="_blank" href="https://www.narindo.com/#/">PT. Narindo Solusi Telekomunikasi</a>.</strong>
                        All rights reserved.
                      </footer>
                </div>
                
            </div> -->
        </div>

    </div>

    

  





