import { Component, Input, OnInit } from "@angular/core";
import {
  NbAlertComponent,
  NbComponentStatus,
  NbDialogRef,
  NbDialogService,
  NbIconConfig,
  NbToastrService,
} from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { SmartTableData } from "../../@core/data/smart-table";
import { listOrderResponse } from "../../models/list_order_dto";
import { ApiClient } from "../../services/api.client";
import { TolakpesananComponent } from "../tolakpesanan/tolakpesanan.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import Swal from "sweetalert2";

@Component({
  selector: "ngx-terimapesan",
  templateUrl: "./terimapesan.component.html",
  styleUrls: ["./terimapesan.component.scss"],
})
export class TerimapesanComponent implements OnInit {
  @Input() title: string;
  @Input() data_confirmation: Array<listOrderResponse>;
  @Input() status: string;
  @Input() status_bulk: string;
  @BlockUI() blockUI: NgBlockUI;

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  status_pesanan = "";
  loading = false;

  source: LocalDataSource = new LocalDataSource();

  constructor(
    private service: SmartTableData,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<TerimapesanComponent>,
    private apiClient: ApiClient,
    private dialogService: NbDialogService
  ) {
    const data = this.service.getData();
    this.source.load(data);
  }

  dismiss() {
    this.ref.close();
  }

  getData() {
    this.title = "Terima";
    console.log("data confirmation", this.data_confirmation);
  }

  ngOnInit(): void {
    this.getData();
    this.status_pesanan = this.status;
  }

  getInputType() {
    return "text";
  }

  async acceptOrder(order_id: any, name: any, shop_id: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    let params_accept = {
      order_id: order_id,
      os_type: name.toLowerCase(),
      shop_id: shop_id,
    };
    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/ack",
      params: params_accept,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      const htmlnya = `<div>${req.msg}</div></br><div>${req.data}</div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Terima Pesanan",
        text: req.msg,
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Terima Pesanan",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }
  }

  async rejectOrder(order_id: any, name: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("order_id", order_id);
    let params_accept = {
      order_id: order_id,
      os_type: name.toLowerCase(),
    };
    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/nack",
      params: params_accept,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      const htmlnya = `<div>${req.data}</div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Tolak Pesanan",
        text: req.msg,
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Tolak Pesanan",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }
  }

  async bulkAckship(data_bulk: any, bulk_type: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("data_bulk", data_bulk);
    this.blockUI.start("Loading...");
    var list_bulk = [];
    data_bulk.forEach((element, i) => {
      console.log("oo", element);
      list_bulk.push({
        order_id: element["order_id"],
        os_type: element["os_name"].toLowerCase(),
        shop_id: element["shop_id"],
      });
    });

    console.log("list_bug", list_bulk);

    let params_request = {
      list: list_bulk,
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/" + bulk_type,
      params: params_request,
    });
    console.log("move page", req.data["failed"].length);
    if (req.data["failed"].length == 0) {
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Terima Pesanan",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      var resp = req.data["failed"];
      const itemsHtml = resp
        .map(
          (a, i) =>
            `<li style='list-style:none;'> ${a.order_id} - ${a.message}</li>`
        )
        .join("");

      const htmlnya = `<div><ul>${itemsHtml}</ul></div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Terima Pesanan",
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }

    // if (req.respcode != 1) {
    //   // this.dismiss()
    //   Swal.close();
    //   const result = await Swal.fire({
    //     title: "Gagal",
    //     text: req.msg,
    //     icon: "success",
    //     buttonsStyling: true,
    //     allowOutsideClick: false,
    //     confirmButtonText: "Ok",
    //   });

    //   if (result.isConfirmed) {
    //     console.log("move page", result.isConfirmed);
    //     console.log("masuk kesini pickup");
    //     this.dismiss();
    //   }
    // } else {
    //   // this.dismiss()
    //   Swal.close();
    //   const result = await Swal.fire({
    //     title: "Sukses",
    //     text: "Berhasil Terima Pesanan",
    //     icon: "success",
    //     buttonsStyling: true,
    //     allowOutsideClick: false,
    //     confirmButtonText: "Ok",
    //   });

    //   if (result.isConfirmed) {
    //     console.log("move page", result.isConfirmed);
    //     console.log("masuk kesini pickup");
    //     this.dismiss();
    //   }
    // }
  }

  showToast(status: NbComponentStatus, message: any) {
    this.toastrService.show(status, message, { status });
  }

  reset() {
    this.selectedChannel = [];
    this.selectedOpe = [];
    this.selectedExpe = [];
    this.selectedService = [];
    this.selectedStatus = [];
  }

  confirmRejectOrder(e: any) {
    console.log("e", e);
    this.data_confirmation = [];
    this.data_confirmation.push(e);
    this.dismiss();
    this.dialogService.open(TolakpesananComponent, {
      context: {
        data: this.data_confirmation,
      },
      closeOnEsc: false,
    });
  }
}
