import { Component, Input, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { EsimResponse, HistoryMappingResponse } from "../../models/list_esim";
import { PreviewEsimComponent } from "../preview-esim/preview-esim.component";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { ApiClient } from "../../services/api.client";
import { FormBuilder } from "@angular/forms";

@Component({
  selector: "ngx-history-mapping",
  templateUrl: "./history-mapping.component.html",
  styleUrls: ["./history-mapping.component.scss"],
})
export class HistoryMappingComponent implements OnInit {
  @Input() invoice: string;
  @Input() title: string;
  @Input() data_confirmation: Array<HistoryMappingResponse>;
  @Input() list: Array<EsimResponse>;
  @Input() status_bulk: string;

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  sn: string = "";

  constructor(
    protected ref: NbDialogRef<PreviewEsimComponent>,
    private apiClient: ApiClient,
    private dialogService: NbDialogService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}
}
