import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
// import { NbDialogRef } from '@nebular/theme';
import { ApiClient } from "../../services/api.client";
import {
  listCourierResponse,
  listOsResponse,
  listShipmentStatusResponse,
  listShopResponse,
} from "../../models/list_filter_dto";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "ngx-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent implements OnInit {
  // @ViewChild('multiSelect') multiSelect;
  @Input() title: string;
  @Input() public filter;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  dropdownOpen = false;
  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_os = [];
  data_shop = [];
  data_shipment_status = [];
  data_courier_list = [
    {
      courier: "Alfatrex",
    },
    {
      courier: "AnterAja",
    },
    {
      courier: "Bluebird Kirim",
    },
    {
      courier: "GoSend",
    },
    {
      courier: "GrabExpress",
    },
    {
      courier: "GTL",
    },
    {
      courier: "ID Express",
    },
    {
      courier: "Indo Paket",
    },
    {
      courier: "Lion Parcel",
    },
    {
      courier: "J&T",
    },
    {
      courier: "JNE",
    },
    {
      courier: "Ninja Xpress",
    },
    {
      courier: "Pos Indonesia",
    },
    {
      courier: "Sentral Cargo",
    },
    {
      courier: "Shopee Xpress",
    },
    {
      courier: "Shopee Xpress Instant",
    },
    {
      courier: "Shopee Xpress Sameday",
    },
    {
      courier: "SiCepat",
    },
    {
      courier: "Custom Logistik",
    },
    {
      courier: "Jasa Kirim Toko",
    },
    {
      courier: "Kurir Rekomendasi",
    },
    {
      courier: "Kurir Toko",
    },
  ];
  data_service_list = [
    {
      service: "Cargo",
    },
    {
      service: "Custom",
    },
    {
      service: "Hemat",
    },
    {
      service: "Instant",
    },
    {
      service: "Next Day",
    },
    {
      service: "Other",
    },
    {
      service: "Reguler",
    },
    {
      service: "Same Day",
    },
    {
      service: "Unknown",
    },
  ];
  os: number = 0;
  selectedItems: string[] = [];
  isDropdownOpen = false; // Add this property

  constructor(
    private apiClient: ApiClient,
    public activeModal: NgbActiveModal
  ) {}

  async getData() {
    this.title = "Filter";
    const params_api = [];
    let req = await this.apiClient.post<listOsResponse>({
      endpoint: "/order/get_os_data",
      params: params_api,
    });
    this.data_os = req.data;

    // let req3 = await this.apiClient.post<listShipmentStatusResponse>({
    //   endpoint: "/order/get_shipment_status",
    //   params: {
    //     date_before: this.filter["date_before"],
    //     date_after: this.filter["date_after"],
    //   },
    // });
    // this.data_shipment_status = req3.data;

    // let req5 = await this.apiClient.post<listCourierResponse>({
    //   endpoint: "/order/service-list",
    //   params: params_api,
    // });
    // this.data_service_list = req5.data;

    this.getById();
  }

  async getById() {
    const params_api = [];
    let req2 = await this.apiClient.post<listShopResponse>({
      endpoint: "/order/get_shop_data",
      params: params_api,
    });
    this.data_shop = req2.data;
  }

  ngOnInit(): void {
    this.getData();
  }

  checkboxChanged(event: any, item: any): void {
    item.selected = event.target.checked;

    if (item.selected) {
      this.filter["courier"].push(item.courier);
      this.selectedItems = this.filter["courier"].join(", ");
    } else {
      const index = this.filter["courier"].indexOf(item.courier);

      if (index !== -1) {
        this.selectedItems = this.filter["courier"].splice(index, 1);

        this.selectedItems = this.filter["courier"].join(", ");
      }
    }
  }

  toggleDropdown() {
    // this.isDropdownOpen = !this.isDropdownOpen;
    this.dropdownOpen = !this.dropdownOpen;
    console.log("hit kesini euyy", this.dropdownOpen);
  }

  getInputType() {
    return "text";
  }

  // toggleSelection(item: any) {
  //   // item.selected = !item.selected;
  //   this.filter.courier.push(item);
  // }

  toggleSelection(items: any) {
    console.log("items", items);
    if (this.isSelected(items)) {
      this.filter.courier = this.filter.courier.filter(
        (item) => item !== items
      );
    } else {
      this.filter.courier.push(items);
    }
    console.log("courier", this.filter.courier);
  }

  isSelected(option: string): boolean {
    return this.filter.courier.indexOf(option) > -1;
  }

  onChange(deviceValue: any) {}

  passBack() {
    this.filter.page = 1;
    this.passEntry.emit(this.filter);

    this.activeModal.close(this.filter);
  }

  reset() {
    this.filter = {
      date_before: this.filter["date_before"],
      date_after: this.filter["date_after"],
      order_id: "",
      os_id: "",
      shop_id: "",
      courier: [],
      shipment_status: "",
      service: "",
      fulfillment: "",
      warehouse_handler: "",
      category: "",
      page: 1,
      search: this.filter["search"],
    };
    this.selectedItems = [];

    this.passEntry.emit(this.filter);
  }
}
