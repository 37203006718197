<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>
    <div class="logo" href="#" (click)="navigateHome()"> <img src="../../../assets/icon/Node_OS.svg"></div>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      class="user-action"
      *nbIsGranted="['view', 'user']"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true">
      <nb-user style=" color: white;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: center;
      background-repeat: no-repeat;
      border-radius: 8px;
      background-position: 10px;" [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user" color="#cccccc" nbContextMenuTag="my-context-menu">
      </nb-user>
      <nb-icon icon="arrow-ios-downward-outline" color="#fff"></nb-icon>
    </nb-action>
  </nb-actions>
</div>
