<nb-card style="overflow: auto; max-width: 1485px; max-height: 800px">
  <div *ngIf="status_bulk === 'bulk'">
    <div *ngFor="let e of data_confirmation[0]; let i = index">
      <div class="row" style="margin-left: -14px; margin-right: 20px">
        <nb-card-header style="width: 100%">
          <div
            class="col-md-12"
            style="
              font-size: 20px;
              font-family: Poppins;
              font-weight: 600;
              color: var(--gray-7, #363740);
            "
          >
            {{ e.invoice_number }}
          </div>
        </nb-card-header>
      </div>

      <nb-card-body>
        <div style="padding-left: 24px; padding-right: 24px">
          <div class="row">
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Item
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Channel</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 20px; padding-top: -20px">
                      <img
                        *ngIf="e.os_id === 1"
                        src="../../../assets/logo/shopee.png"
                        width="100"
                      />
                      <img
                        *ngIf="e.os_id === 2"
                        src="../../../assets/logo/tokopedia.png"
                        width="100"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Operator</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.operator_name }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Store</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.sh_name }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Item</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.order_items.length }} Pesanan
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Total(Rp)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.amount | currency : "Rp. " }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Pengiriman
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Tracking ID</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["package_number"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Expedisi</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.courier }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Service</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.courier_type }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Dikelola Oleh</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.fulfillment_status }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Nama Gudang</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.warehouse_name }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Pengelola Gudang
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.warehouse_handler }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Pickup (Estimation)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["est_shipping_date"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Berat(Kg)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.order_items[0]["weight"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Estimation (Delivered)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.shipping["est_delivered_date"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Fee(Rp)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["fee"] | currency : "Rp. " }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Insurance Price (Rp)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.shipping["insurance_price"] | currency : "Rp. " }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Alamat
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Nama</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_name"] ?? "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">No. Ponsel</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_phone"] ?? "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Alamat</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_address"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Kecamatan</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["district"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Kabupaten/Kota
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.recipient["city"] }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Provinsi</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["province"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Kode Pos</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["postal_code"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Catatan</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.message ?? "-" }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div style="padding-left: 12px; padding-right: 12px">
            <div
              class="row"
              style="
                padding: 10px;
                border-radius: 8px;
                border: 1px solid var(--gray-3, #d7daeb);
                background: var(--white, #fff);
              "
            >
              <div class="col-md-12">
                <div
                  style="
                    font-size: 24px;
                    margin-top: 17px;
                    font: Poppins;
                    font-weight: bold;
                    margin-bottom: 20px;
                  "
                >
                  List Produk
                </div>
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <table class="table table-bordered">
                    <thead
                      style="opacity: 0.20000000298023224; background: #d9d9d9"
                    >
                      <th>ID</th>
                      <th></th>
                      <th>Nama Produk</th>
                      <th>Sub Invoice</th>
                      <th>QTY</th>
                      <th>MSISDN</th>
                      <th>Status Penembakan</th>
                      <th>Price (Rp)</th>
                      <th>Total (Rp)</th>
                      <th>Catatan</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let b of e.order_items; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <img [src]="e.product_picture" alt="" width="70" />
                        </td>
                        <td>{{ b.product_name }}</td>
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <li style="list-style: none">
                              {{ a.sub_invoice_number }}
                            </li>
                          </ul>
                        </td>
                        <td>{{ b.quantity }}</td>
                        <!-- <td>{{e.injection[i].msisdn}} ?? '-'</td> -->
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <li style="list-style: none">
                              {{ a.msisdn }}
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <li style="list-style: none">
                              <button
                                *ngIf="a.is_injected === 1"
                                type="button"
                                class="btn btn-bg-blue"
                                style="
                                  background-color: #008000;
                                  color: #fff;
                                  border-radius: 24px;
                                "
                              >
                                Success
                              </button>
                              <button
                                *ngIf="a.is_injected === 2"
                                type="button"
                                class="btn btn-bg-blue"
                                style="
                                  background-color: #f2994a;
                                  color: #fff;
                                  border-radius: 24px;
                                "
                              >
                                Pending
                              </button>
                              <button
                                *ngIf="a.is_injected === 0"
                                type="button"
                                class="btn btn-bg-blue"
                                style="
                                  background-color: #eb5757;
                                  color: #fff;
                                  border-radius: 24px;
                                "
                              >
                                Unprocessed
                              </button>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <div *ngIf="b.discounted_price === 0">
                            {{ b.original_price | currency : "Rp. " }}
                          </div>
                          <div *ngIf="b.discounted_price !== 0">
                            {{ b.discounted_price | currency : "Rp. " }}
                          </div>
                        </td>
                        <td>
                          <div *ngIf="b.discounted_price === 0">
                            {{
                              b.quantity * b.original_price | currency : "Rp. "
                            }}
                          </div>
                          <div *ngIf="b.discounted_price !== 0">
                            {{
                              b.quantity * b.discounted_price
                                | currency : "Rp. "
                            }}
                          </div>
                        </td>
                        <td>{{ b.message ?? "-" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </div>
  </div>

  <div *ngIf="status_bulk === 'none'">
    <div *ngFor="let e of data_confirmation; let i = index">
      <div class="row">
        <nb-card-header style="width: 100%">
          <div
            class="col-md-12"
            style="
              font-size: 20px;
              font-family: Poppins;
              font-weight: 600;
              color: var(--gray-7, #363740);
            "
          >
            {{ e.invoice_number }}
          </div>
        </nb-card-header>
      </div>

      <nb-card-body>
        <div style="padding-left: 24px; padding-right: 24px">
          <div class="row">
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Item
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Channel</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 20px; padding-top: -20px">
                      <img
                        *ngIf="e.os_id === 1"
                        src="../../../assets/logo/shopee.png"
                        width="100"
                      />
                      <img
                        *ngIf="e.os_id === 2"
                        src="../../../assets/logo/tokopedia.png"
                        width="100"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Operator</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.operator_name }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Store</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.sh_name }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Item</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.order_items.length }} Pesanan
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Total(Rp)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.amount | currency : "Rp. " }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Pengiriman
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Tracking ID</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["package_number"] ?? "-" }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Expedisi</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.courier ?? "-" }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Service</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.courier_type }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Pickup (Estimation)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["est_shipping_date"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Berat(Kg)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.order_items[0]["weight"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Estimation (Delivered)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.shipping["est_delivered_date"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Fee(Rp)</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.shipping["fee"] | currency : "Rp. " }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Insurance Price (Rp)
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="width: 70px; padding-left: 0">
                      {{ e.shipping["insurance_price"] | currency : "Rp. " }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-4">
              <div
                style="
                  font-size: 24px;
                  margin-top: 17px;
                  font: Poppins;
                  font-weight: bold;
                  margin-bottom: 16px;
                "
              >
                Info Alamat
              </div>
              <table class="table table-borderless table-responsive-sm">
                <tbody>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Nama</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_name"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">No. Ponsel</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_phone"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Alamat</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["receiver_address"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Kecamatan</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["district"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">
                      Kabupaten/Kota
                    </th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">{{ e.recipient["city"] }}</td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Provinsi</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["province"] }}
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Kode Pos</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.recipient["postal_code"] }}
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th style="width: 100px; padding-left: 0">Catatan</th>
                    <th
                      style="width: 10px; padding-left: 5px; padding-right: 5px"
                    >
                      :
                    </th>
                    <td style="padding-left: 0">
                      {{ e.message ?? "-" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div style="padding-left: 12px; padding-right: 12px">
            <div
              class="row"
              style="
                padding: 10px;
                border-radius: 8px;
                border: 1px solid var(--gray-3, #d7daeb);
                background: var(--white, #fff);
              "
            >
              <div class="col-md-12">
                <div
                  style="
                    font-size: 24px;
                    margin-top: 17px;
                    font: Poppins;
                    font-weight: bold;
                    margin-bottom: 20px;
                  "
                >
                  List Produk
                </div>
                <div class="table-wrapper-scroll-y my-custom-scrollbar">
                  <table class="table table-bordered">
                    <thead
                      style="opacity: 0.20000000298023224; background: #d9d9d9"
                    >
                      <th>ID</th>
                      <th></th>
                      <th>Nama Produk</th>
                      <th>Sub Invoice</th>
                      <th>QTY</th>
                      <th>MSISDN</th>
                      <th>Box No.</th>
                      <th>Status Penembakan</th>
                      <th>Price (Rp)</th>
                      <th>Total (Rp)</th>
                      <th>Catatan</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let b of e.order_items; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>
                          <img [src]="e.product_picture" alt="" width="70" />
                        </td>
                        <td>{{ b.product_name }}</td>
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <div
                              *ngFor="
                                let c of a.items;
                                let i = index;
                                let odd = odd
                              "
                            >
                              <li
                                style="list-style: none"
                                *ngIf="c.product === b.product_name"
                              >
                                {{ c.sub_invoice_number ?? "-" }}
                              </li>
                            </div>
                          </ul>
                        </td>
                        <td>{{ b.quantity }}</td>
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <div
                              *ngFor="
                                let c of a.items;
                                let i = index;
                                let odd = odd
                              "
                            >
                              <li
                                style="list-style: none"
                                *ngIf="c.product === b.product_name"
                              >
                                {{ c.msisdn ?? "-" }}
                              </li>
                            </div>
                          </ul>
                        </td>
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <div
                              *ngFor="
                                let c of a.items;
                                let i = index;
                                let odd = odd
                              "
                            >
                              <li
                                style="list-style: none"
                                *ngIf="c.product === b.product_name"
                              >
                                {{ c.box_no ?? "-" }}
                              </li>
                            </div>
                          </ul>
                        </td>
                        <!-- <td>{{e.injection[i].is_injected}}</td> -->
                        <td>
                          <ul
                            *ngFor="
                              let a of e.injection;
                              let i = index;
                              let odd = odd
                            "
                          >
                            <div
                              *ngFor="
                                let c of a.items;
                                let i = index;
                                let odd = odd
                              "
                            >
                              <li
                                style="list-style: none"
                                *ngIf="c.product === b.product_name"
                              >
                                <button
                                  *ngIf="c.is_injected === 1"
                                  type="button"
                                  class="btn btn-bg-blue"
                                  style="
                                    background-color: #008000;
                                    color: #fff;
                                    border-radius: 24px;
                                  "
                                >
                                  Success
                                </button>
                                <button
                                  *ngIf="c.is_injected === 2"
                                  type="button"
                                  class="btn btn-bg-blue"
                                  style="
                                    background-color: #f2994a;
                                    color: #fff;
                                    border-radius: 24px;
                                  "
                                >
                                  Pending
                                </button>
                                <button
                                  *ngIf="c.is_injected === 0"
                                  type="button"
                                  class="btn btn-bg-blue"
                                  style="
                                    background-color: #eb5757;
                                    color: #fff;
                                    border-radius: 24px;
                                  "
                                >
                                  Unprocessed
                                </button>
                              </li>
                            </div>
                          </ul>
                        </td>
                        <td>
                          <div *ngIf="b.discounted_price === 0">
                            {{ b.original_price | currency : "Rp. " }}
                          </div>
                          <div *ngIf="b.discounted_price !== 0">
                            {{ b.discounted_price | currency : "Rp. " }}
                          </div>
                        </td>

                        <td>
                          <div *ngIf="b.discounted_price === 0">
                            {{
                              b.quantity * b.original_price | currency : "Rp. "
                            }}
                          </div>
                          <div *ngIf="b.discounted_price !== 0">
                            {{
                              b.quantity * b.discounted_price
                                | currency : "Rp. "
                            }}
                          </div>
                          <!-- {{b.quantity * b.discounted_price | currency:'Rp. '}}</td> -->
                        </td>

                        <td>{{ b.message ?? "-" }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-card-body>
    </div>
  </div>

  <div *ngIf="status_bulk === 'bulk'">
    <nb-card-footer>
      <div class="row">
        <div
          *ngIf="status === 'terima'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="
              bulkAckship(data_confirmation[0], 'bulk-ack', 'Menerima Pesanan')
            "
            class="btn btn-bg-blue pull-right"
            style="background-color: #007bff; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Terima Pesanan</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>

        <div
          *ngIf="status === 'pickup'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="
              bulkAckship(data_confirmation[0], 'bulk-ship', 'Request Pickup')
            "
            class="btn btn-bg-blue pull-right"
            style="background-color: #f2994a; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Request Pickup</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>

        <div
          *ngIf="status === 'label'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="
              bulkprintLabel(
                data_confirmation[0],
                data_confirmation[0]['os_name']
              )
            "
            class="btn btn-bg-blue pull-right"
            style="background-color: #007bff; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Print Label</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>
      </div>
    </nb-card-footer>
  </div>

  <div *ngIf="status_bulk === 'none'">
    <nb-card-footer>
      <div class="row">
        <div
          *ngIf="status === 'terima'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="
              acceptOrder(
                data_confirmation[0]['order_id'],
                data_confirmation[0]['os_name'],
                data_confirmation[0]['shop_id']
              )
            "
            class="btn btn-bg-blue pull-right"
            style="background-color: #007bff; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Terima Pesanan</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>

        <div
          *ngIf="status === 'tolak'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="confirmRejectOrder(data_confirmation[0])"
            class="btn btn-bg-blue pull-right"
            style="background-color: #eb5757; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Tolak Pesanan</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>

        <div
          *ngIf="status === 'pickup'"
          class="col-md-12"
          style="text-align: right; padding-left: 39px; padding-right: 39px"
        >
          <button
            type="button"
            (click)="requestPickup(data_confirmation[0], 'none')"
            class="btn btn-bg-blue pull-right"
            style="background-color: #f2994a; max-width: 354px"
          >
            <img src="../../../assets/icon/icon-export-csv.svg" />
            Request Pickup</button
          >&nbsp;&nbsp;
          <button
            type="button"
            class="btn btn-bg-blue pull-right"
            (click)="dismiss()"
            style="
              background-color: transparent;
              border-color: #007bff;
              max-width: 114px;
              color: #007bff;
            "
          >
            <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
            Batal
          </button>
        </div>
      </div>
    </nb-card-footer>
  </div>
</nb-card>
