<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Filter Product</h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label style="font-size: 16px; font: Poppins">Order Id</label>
        <input
          type="text"
          [type]="getInputType()"
          class="form-control"
          [(ngModel)]="filter.order_id"
          nbInput
          style="background-color: transparent"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label style="font-size: 16px; font: Poppins">Channel</label>
        <select
          class="form-control"
          (ngModelChange)="onChange($event)"
          [(ngModel)]="filter.os_id"
        >
          <option value="">All Channel</option>
          <option *ngFor="let item of data_os" [ngValue]="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Store Name</label>
        <select class="form-control" [(ngModel)]="filter.shop_id">
          <option value="">All Store Name</option>
          <option *ngFor="let item of data_shop" [ngValue]="item.shop_id">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Category</label>

        <select class="form-control" [(ngModel)]="filter.category">
          <option value="">All Category</option>
          <option value="1">USIM</option>
          <option value="2">ESIM</option>
          <option value="3">MERCHANDISE</option>
        </select>
      </div>
    </div>
    <!-- <div class="col-md-6">
          <div class="form-group">
              <label>Shipment Status</label>
              <select class="form-control" [(ngModel)]="filter.shipment_status">
                  <option *ngFor="let item of data_shipment_status" [ngValue]="item.status">{{item.status}}</option>
              </select>
          </div>
         
      </div> -->
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label style="font-size: 16px; font: Poppins">Expedition</label>

        <select
          class="form-control"
          (ngModelChange)="toggleSelection($event)"
          [(ngModel)]="filter.courier"
        >
          <option value="">All Expedition</option>
          <option
            *ngFor="let item of data_courier_list"
            [ngValue]="item.courier"
          >
            {{ item.courier }}
          </option>
        </select>
        <!-- <label for="options">Select Options:</label>
        <div class="dropdown" style="width: 100%" [class.show]="dropdownOpen">
          <button
            class="btn btn-secondary dropdown-toggle"
            (click)="toggleDropdown()"
            type="button"
            style="width: 100%"
          >
            Select Courier
          </button>
          <div *ngIf="dropdownOpen" class="dropdown-menu" style="width: 100%">
            <div class="dropdown-item" *ngFor="let item of data_courier_list">
              <input
                type="checkbox"
                [value]="item.courier"
                (change)="toggleSelection($event.target.value)"
                [checked]="isSelected(item.courier)"
              />
              {{ item.courier }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Service</label>
        <select class="form-control" [(ngModel)]="filter.service">
          <option value="">All Service</option>
          <option
            *ngFor="let item of data_service_list"
            [ngValue]="item.service"
          >
            {{ item.service }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Warehouse</label>

        <select class="form-control" [(ngModel)]="filter.warehouse_handler">
          <option value="">All Warehouse</option>
          <option value="Narindo">Narindo</option>
          <option value="XL Center">XL Center</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Fulfillment</label>

        <select class="form-control" [(ngModel)]="filter.fulfillment">
          <option value="">All Fulfillment</option>
          <option value="BY_OS">OS</option>
          <option value="BY_SELLER">SELLER</option>
          <option value="BY_OTHERS">OTHERS</option>
        </select>
      </div>
    </div>
    <!-- <div class="col-md-6">
      <div class="form-group">
        <label>Category</label>
        <select class="form-control" [(ngModel)]="filter.category">
          <option value="1">USIM</option>
          <option value="2">ESIM</option>
          <option value="3">MERCHANDISE</option>
        </select>
      </div>
    </div> -->
  </div>
</div>
<div class="modal-footer">
  <div class="row">
    <div class="col-md-12" style="text-align: right">
      <button
        type="button"
        (click)="reset()"
        class="btn btn-bg-blue pull-right"
        style="
          background-color: transparent;
          border-color: #007bff;
          max-width: 114px;
          color: #007bff;
        "
      >
        Reset</button
      >&nbsp;&nbsp;
      <button
        type="button"
        class="btn btn-bg-blue pull-right"
        (click)="passBack()"
        style="background-color: #007bff; max-width: 354px; color: #ffff"
      >
        <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
        Apply
      </button>
    </div>
  </div>
</div>
