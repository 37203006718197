<nb-card style="max-width: 428px">
  <nb-card-header style="padding-top: 12px; padding-bottom: 12px">
    <div class="row">
      <div
        class="col-md-12"
        style="
          color: var(--black-gray, #363740);
          text-align: center;
          /* Semi Bold - 18 */
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        "
      >
        {{ type === "sync" ? "Sinkronisasi Pesan" : "Insert Order Invoice" }}
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div
      style="
        color: var(--black-gray, #363740);
        text-align: center;
        /* Medium - 16 */
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      "
    >
      Konfirmasi Sinkronisasi Pesanan, <br />Apakah anda yakin untuk melakukan
      sinkronisasi?
    </div>
    <br /><br />

    <div *ngIf="status_bulk === 'bulk'">
      <div class="form-group">
        <strong>Channel:</strong><br />
        <nb-select
          placeholder="Channel"
          [(selected)]="os_type"
          [(ngModel)]="os_type"
          style="width: 100%"
        >
          <!-- Menambahkan opsi secara manual -->

          <nb-option value="tokopedia">Tokopedia</nb-option>
          <!-- <nb-option value="3">On Process Injection</nb-option> -->
          <nb-option value="shopee">Shopee</nb-option>
          <nb-option value="webstore">Webstore</nb-option>
        </nb-select>
      </div>
      <strong>Start Date:</strong><br />
      <input
        style="
          width: 230px;
          height: 40px;
          border-radius: 8px;
          border: 1px solid var(--light-2, #e8ecff);
          background: var(--white, #fff);
        "
        nbInput
        placeholder="Pick Date"
        [nbDatepicker]="dateTimePickers"
        [(ngModel)]="selectedDateTime"
        (ngModelChange)="onKeyUpDate($event)"
      />
      <nb-date-timepicker withSeconds #dateTimePickers></nb-date-timepicker>
      <br /><br />
      <strong>End Date:</strong><br />
      <input
        style="
          width: 230px;
          height: 40px;
          border-radius: 8px;
          border: 1px solid var(--light-2, #e8ecff);
          background: var(--white, #fff);
        "
        nbInput
        placeholder="Pick Date"
        [nbDatepicker]="dateTimePicker"
        [(ngModel)]="selectedDateTime1"
        (ngModelChange)="onKeyUpDate1($event)"
      />
      <nb-date-timepicker withSeconds #dateTimePicker></nb-date-timepicker>
    </div>
  </nb-card-body>

  <nb-card-footer>
    <div class="row">
      <div class="col-md-8" style="text-align: left">
        <button
          type="button"
          (click)="getSync()"
          [disabled]="criteriaMet()"
          class="btn btn-bg-blue pull-right"
          style="background-color: #7066e0; max-width: 324px; color: #ffffff"
        >
          <!-- <img src="../../../assets/icon/icon-export-csv.svg" /> -->
          Sinkronisasi Pesanan
        </button>
      </div>

      <div class="col-md-4" style="text-align: right">
        <button
          type="button"
          class="btn btn-bg-blue pull-right"
          (click)="dismiss()"
          style="
            background-color: transparent;
            border-color: #007bff;
            max-width: 114px;
            color: #007bff;
          "
        >
          <!-- <img src="../../../assets/icon/icon-export-csv.svg"> -->
          Kembali
        </button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
