import { Component, Input, OnInit } from "@angular/core";
import {
  NbCalendarRange,
  NbDateService,
  NbDialogRef,
  NbToastrService,
} from "@nebular/theme";
import { listOrderResponse } from "../../models/list_order_dto";
import Swal from "sweetalert2";
import { ApiClient } from "../../services/api.client";
import { KonfirmasipickupComponent } from "../konfirmasipickup/konfirmasipickup.component";
import { DatePipe } from "@angular/common";

@Component({
  selector: "ngx-konfirmasipesan",
  templateUrl: "./konfirmasipesan.component.html",
  styleUrls: ["./konfirmasipesan.component.scss"],
})
export class KonfirmasipesanComponent implements OnInit {
  @Input() data_confirmation: Array<listOrderResponse>;
  @Input() type: string;
  @Input() status_bulk: string;

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  status_pesanan = "";
  os_type = "";
  selectedDateTime: Date = new Date();
  selectedDateTime1: Date = new Date();
  start_date = "";
  end_date = "";
  // range: NbCalendarRange<Date>;

  constructor(
    private apiClient: ApiClient,
    private toastrService: NbToastrService,
    private datePipe: DatePipe,
    protected dateService: NbDateService<Date>,
    protected ref: NbDialogRef<KonfirmasipickupComponent>
  ) {
    this.os_type = "tokopedia";
    this.start_date = this.datePipe.transform(
      this.selectedDateTime.setHours(0, 0, 0, 0),
      "yyyy-MM-dd HH:mm:ss"
    );
    this.end_date = this.datePipe.transform(
      this.selectedDateTime,
      "yyyy-MM-dd HH:mm:ss"
    );
  }

  ngOnInit(): void {
    console.log("type", this.type);
  }

  dismiss() {
    this.ref.close();
  }

  ngModelDate = {
    start: new Date(),
    end: new Date(),
  };

  get monthStart(): Date {
    return this.dateService.getMonthStart(new Date());
  }

  get monthEnd(): Date {
    return this.dateService.getMonthEnd(new Date());
  }

  criteriaMet() {
    if (this.status_bulk === "bulk") {
      this.disablebulkSinkron();
    } else {
      this.disablesinkron();
    }
  }

  disablebulkSinkron(): boolean {
    // Check your criteria here
    return (
      this.os_type !== "" &&
      this.start_date.trim() !== "" &&
      this.end_date.trim() !== ""
    );
  }

  disablesinkron() {}

  onKeyUpDate(e: any) {
    // console.log(this.datePipe.transform(e.start, "yyyy-MM-dd"));
    // console.log(this.datePipe.transform(e.end, "yyyy-MM-dd"));
    console.log("e", this.datePipe.transform(e, "yyyy-MM-dd HH:mm:ss"));
    this.start_date = this.datePipe.transform(e, "yyyy-MM-dd HH:mm:ss");
  }

  onKeyUpDate1(e: any) {
    // console.log(this.datePipe.transform(e.start, "yyyy-MM-dd"));
    // console.log(this.datePipe.transform(e.end, "yyyy-MM-dd"));
    console.log("ooo", this.datePipe.transform(e, "yyyy-MM-dd HH:mm:ss"));
    this.end_date = this.datePipe.transform(e, "yyyy-MM-dd HH:mm:ss");
  }

  async sinkron() {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("data confirmation", this.data_confirmation[0]["order_id"]);
    let params_request = {
      os_type: this.data_confirmation[0]["os_name"].toLowerCase(),
      order_id: this.data_confirmation[0]["order_id"],
      shop_id: this.data_confirmation[0]["shop_id"],
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/update",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      Swal.fire({
        icon: "error",
        title: req.msg,
        text: req.data.toString(),
      });
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Sinkronisasi Berhasil",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }

      // Swal.fire('Berhasil Request Pickup Pesanan')
    }
  }

  async bulkSinkron() {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    let params_request = {
      os_type: this.os_type,
      date_before: this.start_date,
      date_after: this.end_date,
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/bulk_update",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      Swal.fire({
        icon: "error",
        title: req.msg,
        text: req.data.toString() ?? "",
      });
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sinkronisasi Berhasil",
        text:
          "Estimasi Sinkronisasi sekitar " +
          req.data["estimated_time_minutes"] +
          " menit",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }

      // Swal.fire('Berhasil Request Pickup Pesanan')
    }
  }

  getSync() {
    if (this.status_bulk === "bulk") {
      this.bulkSinkron();
    } else {
      this.sinkron();
    }
  }
}
