import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import { ApiClient } from "../../services/api.client";
import {
  NbComponentStatus,
  NbDialogRef,
  NbDialogService,
  NbToastrService,
} from "@nebular/theme";
import { listOrderResponse } from "../../models/list_order_dto";
import Swal from "sweetalert2";

@Component({
  selector: "ngx-konfirmasipickup",
  templateUrl: "./konfirmasipickup.component.html",
  styleUrls: ["./konfirmasipickup.component.scss"],
})
export class KonfirmasipickupComponent {
  @Input() data_confirmation: Array<listOrderResponse>;
  @Input() status_bulk: string;
  @Output() dataPassed = new EventEmitter<string>();

  selectedOpe = [];
  selectedChannel = [];
  selectedStatus = [];
  selectedExpe = [];
  selectedService = [];
  data_ = [];
  status_pesanan = "";

  constructor(
    private apiClient: ApiClient,
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<KonfirmasipickupComponent>,
    private ngZone: NgZone
  ) {}

  dismiss() {
    this.ref.close();
  }

  showToast(status: NbComponentStatus, message: any) {
    this.toastrService.show(status, message, { status });
  }

  showAlertResult() {
    alert("Function called from Swal alert.");
  }

  async requestPickup() {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("data confirmation", this.data_confirmation[0]["order_id"]);
    let params_request = {
      os_type: this.data_confirmation[0]["os_name"].toLowerCase(),
      order_id: this.data_confirmation[0]["order_id"],
      shop_id: this.data_confirmation[0]["shop_id"],
      // package_number: 'this.data_confirmation'
      package_number:
        this.data_confirmation[0]["shipping"]["package_number"] ?? "-",
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/ship",
      params: params_request,
    });
    if (req.respcode != 1) {
      // this.dismiss()
      Swal.close();
      Swal.fire({
        icon: "error",
        title: req.msg,
        text: req.data.toString(),
      });
    } else {
      // this.dismiss()
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Request Pickup Diterima",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }

      // Swal.fire('Berhasil Request Pickup Pesanan')
    }
  }

  async bulkrequestPickup(data_bulk: any) {
    Swal.fire({
      title: "",
      html: "", // add html attribute if you want or remove
      imageUrl: "../../../../assets/gif/loading.gif",
      allowOutsideClick: false,
      showCloseButton: false,
      showConfirmButton: false,
      customClass: "swal-without-bg",
    });

    console.log("data_bulk", data_bulk);
    var list_bulk = [];
    data_bulk.forEach((element, i) => {
      console.log("oo", element);
      list_bulk.push({
        os_type: element["os_name"].toLowerCase(),
        order_id: element["order_id"],
        shop_id: element["shop_id"],
        package_number: element["shipping"]["package_number"],
      });
    });

    console.log("list_bug", list_bulk);

    let params_request = {
      list: list_bulk,
    };

    let req = await this.apiClient.post<listOrderResponse>({
      endpoint: "/order/bulk-ship",
      params: params_request,
    });
    // if(req.respcode != 1){
    //   this.dismiss()
    //   Swal.close()
    //   Swal.fire({
    //     icon: 'error',
    //     title: req.msg,
    //     text: req.data.toString(),
    //   })
    // }else{
    //   // this.dismiss()
    //   Swal.close()
    //   const result = await Swal.fire(
    //     {
    //       title: "Sukses",
    //       text: "Request Pickup Diterima",
    //       icon: "success",
    //       buttonsStyling: true,
    //       allowOutsideClick: false,
    //       confirmButtonText: "Ok",
    //     })

    //     if (result.isConfirmed) {
    //       console.log('move page', result.isConfirmed);
    //       console.log("masuk kesini pickup")
    //       this.dismiss();
    //     }

    // }
    console.log("move page", req.data["failed"].length);
    if (req.data["failed"].length == 0) {
      Swal.close();
      const result = await Swal.fire({
        title: "Sukses",
        text: "Berhasil Request Pickup",
        icon: "success",
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    } else {
      var resp = req.data["failed"];
      const itemsHtml = resp
        .map(
          (a, i) =>
            `<li style='list-style:none;'> ${a.order_id} - ${a.message}</li>`
        )
        .join("");

      const htmlnya = `<div><ul>${itemsHtml}</ul></div>`;

      const result = await Swal.fire({
        icon: "error",
        title: "Gagal Mapping MSISDN",
        html: htmlnya,
        buttonsStyling: true,
        allowOutsideClick: false,
        confirmButtonText: "Ok",
      });

      if (result.isConfirmed) {
        console.log("move page", result.isConfirmed);
        console.log("masuk kesini pickup");
        this.dismiss();
      }
    }
  }
}
